import { Network } from '@capacitor/network';
import { useIonRouter } from '@ionic/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import toast from 'react-hot-toast';
import { XactModel } from '../graphql/GetJobsList';
import useAdjusters from '../hooks/adjusters';
import useVerticals from '../hooks/verticals';
import { useAuth } from '../hooks/authContext';
import useBranches from '../hooks/branches';
import useConstructionManagers from '../hooks/constructionManagers';
import useGooglePlaces from '../hooks/googlePlaces';
import useInsuranceCompanies from '../hooks/insuranceCompanies';
import useLossTypes from '../hooks/lossTypes';
import useProjectManagers from '../hooks/projectManagers';
import usePropertyManagers from '../hooks/propertyManagers';
import useStorage from '../hooks/storage';
import { DefaultPhaseIds } from './helper/AddPhaseActionSheetConst';
import { ApiStatus, ClaimOnlineStatus, ClaimType, OTHER_VERTICAL, Region } from './helper/Const';
import { emailRegex, phoneRegex, zipCodeRegex } from './helper/FormsHelper';
import { updateRefetchKey } from './helper/JobsHelper';
import { PhaseListType } from './helper/PhaseList';
import getPlaceDetails from '../restAPI/getCoordinates';
import { formatDataForm, getClaimStorage } from './helper/ClaimHelper';
import { ClaimFormData, SaveClaimOperation, saveClaim, useCreateNewClaimMutation, useEditClaimMutation } from './helper/offlineHelper/ClaimDetailsHelper';
import useClaimDetails from '../hooks/useClaimDetails';
import { useClaimOffline } from '../contexts/ClaimOfflineContext';
import { usePermissions, PermissionsList } from '../hooks/permissions';
import { getErrorMsg } from './helper/AppHelper';

interface RouteParams {
  jobIdx: string;
  isOffline?: string;
}

const closeIcon = 'assets/images/icons/ic_close.png';

const AddJobViewModel = () => {
  const { t } = useTranslation();
  const { userState, user, checkTokenExpiration } = useAuth()!;
  const { jobIdx, isOffline } = useParams<RouteParams>();
  const region = userState.userAuthPayload?.regionId!;
  const storage = useStorage();
  const navigation = useIonRouter();
  const [phaseList, setPhaseList] = useState<PhaseListType[]>([]);
  const [xactTransactionId, setXactTransactionId] = useState<string | null>(null);
  const [setPlaceName, suggestions] = useGooglePlaces();
  const [lossAddressLat, setLossAddressLat] = useState<number>();
  const [lossAddressLon, setLossAddressLon] = useState<number>();
  const [isPhaseModalOpen, setIsPhaseModalOpen] = useState(false);
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
  const [isCommercial, setIsCommercial] = useState(false);
  const [isOtherVertical, setIsOtherVertical] = useState(false);
  const [province, setProvince] = useState('');
  const [saveError, setSaveError] = useState('');
  const [isReadOnlyField, setIsReadOnlyField] = useState(false);
  const [claimIndx, setClaimIndx] = useState(0);
  const [claim, setClaim] = useState<any>();
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [showSuccessLocallyToast, setShowSuccessLocallyToast] = useState<boolean>(false);
  const [operation, setOperation] = useState<SaveClaimOperation>(SaveClaimOperation.ADD);
  const { offlineClaims: offlineClaimStorage, refresh } = useClaimOffline()!;
  const maxDate = new Date().toISOString();
  const offlineClaim = useMemo(() => offlineClaimStorage || [], [offlineClaimStorage]);
  const history = useHistory();

  const [isVerticalVisible, setIsVerticalVisible] = useState(false);

  const onAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlaceName(event.target.value);
  };

  const branches = useBranches(region, userState, storage);
  const brancheOptions = branches.map(branch => ({ label: branch.branchCode, value: branch.branchCode }));

  const projectManagers = useProjectManagers(region, userState, storage);
  const projectManagerOptions = projectManagers.map(manager => ({ label: manager.value, value: manager.id }));

  const propertyManagers = usePropertyManagers(region, userState, storage);
  const propertyManagerOptions = propertyManagers.map(manager => ({ label: `${manager.firstName} ${manager.lastName}`, value: manager.adjCode }));

  const constructionManagers = useConstructionManagers(region, userState, storage);
  const constructionManagerOptions = constructionManagers.map(constructionManager => ({ label: `${constructionManager.firstName} ${constructionManager.lastName}`, value: constructionManager.id }));

  const adjusters = useAdjusters(region, userState, storage);
  const adjusterOptions = adjusters.map(adjuster => ({ label: `${adjuster.firstName} ${adjuster.lastName}`, value: adjuster.adjCode }));
  
  const verticals = useVerticals(userState, storage);
  const verticalOptions = verticals.map(vertical => ({ label: vertical.name, value: vertical.id }));

  const insuranceCompanies = useInsuranceCompanies(region, userState, storage);
  const insuranceCompaniesOptions = insuranceCompanies.filter(insuranceCompanie => insuranceCompanie.active)
    .map(insuranceCompanie => ({ label: insuranceCompanie.company, value: insuranceCompanie.coCode }));

  const lossTypes = useLossTypes(region, userState, storage);
  const lossTypeOptions = lossTypes.map(lossType => ({ label: lossType.lossCategory, value: lossType.lossCategory }));
  const [adjusterDefaultValue, setAdjusterDefaultValue] = useState(false);

  const { permissions } = usePermissions();
  const isAddClaimEnabled = permissions.includes(PermissionsList.AddClaims);
  const isEditClaimsEnabled = permissions.includes(PermissionsList.EditClaims);
  const isSendToXAEnabled = permissions.includes(PermissionsList.AllowSendToXA);

  useEffect(() => {
    if (permissions.length > 0 && ((!isAddClaimEnabled && !jobIdx) || (!isEditClaimsEnabled && jobIdx))) {
      history.replace('/tabs/jobs');
    }
  }, [history, isAddClaimEnabled, isEditClaimsEnabled, jobIdx, permissions]);

  const schema = yup.object({
    brId: yup.string().required(t('branchIdIsRequired')),
    projName: yup
      .string()
      .required(t('projectNameIsRequired'))
      .max(60, t('projectNameMaxLengthIs60')),
    projAddr: yup
      .string()
      .when({
        is: (val: string) => val.length > 0,
        then: yup.string().max(100, t('projectAddressMaxLengthIs100')),
      }),
    projCity: yup
      .string()
      .required(t('projectCityIsRequired'))
      .when({
        is: (val: string) => val.length > 0,
        then: yup.string().max(25, t('projectCityMaxLengthIs25')),
      }),
    contactPhone: yup
      .string()
      .ensure()
      .when({
        is: (val: string) => val?.length > 0,
        then: yup.string().matches(phoneRegex, { message: t('invalidPhoneNumber') }),
      }),
    projProv: yup
      .string()
      .required(t('provinceIsRequired'))
      .when({
        is: (val: string) => val?.length > 0,
        then: yup.string().max(2, t('projectProvinceMaxLengthIs2')),
      }),
    projZip: yup
      .string()
      .ensure()
      .when({
        is: (val: string) => val?.length > 0,
        then: yup
          .string()
          .length(7, t('invalidZipCode'))
          .matches(zipCodeRegex, { message: t('invalidZipCode') }),
      }),
    jobPcoord: yup.string().required(t('projectManagerIsRequired')), // project manager
    pcId: yup.string(),
    lossCat: yup.string().required(t('categoryIsRequired')), // construction manager
    pmgrName: yup.string(), // property manager
    adjCode: yup.string().required(t('adjusterIsRequired')), // adjuster
    claimCo: yup.string(), // insurer
    contactName: yup
      .string()
      .when({
        is: (val: string) => val?.length > 0,
        then: yup.string().max(70, t('contactNameMaxLengthIs70')),
      }),
    calledIn: yup.string().required(t('calledInByIsRequired')), // called in by
    jobDOpen: yup.string().required(t('calledInOnIsRequired')), // called in on
    lossDate: yup.string().nullable(), // loss date
    dedAmt: yup
      .string()
      .when({
        is: (val: string) => val?.length > 0,
        then: yup.string().matches(/^[1-9]\d*(\.\d+)?$/, { message: t('deductibleMustBeANumber') }),
      }),
    claimNo: yup.string()
      .when({
        is: (val: string) => val?.length > 0,
        then: yup.string().max(25, t('claimNumberMaxLengthIs25')),
      }),
    lossType: yup.string().required(t('lossTypeIsRequired')),
    projEmail: yup
      .string()
      .when({
        is: (val: string) => val?.length > 0,
        then: yup.string().matches(emailRegex, { message: t('pleaseEnterAValidEmailAddress') }),
      }),
    yearOfConstruction: yup.string(),
    verticalId: yup.number()
      .when('lossCat', { is: (value: string) => value === ClaimType.Commercial.toString(),
        then: yup.number().required(t('verticalRequired')),
      }),
    verticalName: yup.string()
      .when('verticalId', { is: (value: number) => value === OTHER_VERTICAL && region !== Region.Quebec,
        then: yup.string().required(t('otherVerticalRequired')),
      }),
    policyNo: yup.string(),
  }).required();

  const { handleSubmit, register, reset, setValue, clearErrors, control, watch, formState: { errors, isValid } } = useForm<ClaimFormData>({
    resolver: yupResolver(schema),
  });

  const addPhaseItem = (value: PhaseListType) => {
    setPhaseList([
      ...phaseList,
      {
        id: value.id,
        phase: value.phase,
        phaseId: value.phaseId,
        phaseCode: value.phaseCode,
        description: value.description,
        estValue: value.estValue,
        projectManagerId: value.projectManagerId,
        projectManager: value.projectManager,
      },
    ]);
  };

  const deleteItem = (item: PhaseListType) => {
    const arrayCopy = [...phaseList];
    const index = arrayCopy.findIndex(object => object.id === item.id);

    if (index > -1) {
      arrayCopy.splice(index, 1);
    }
    setPhaseList(arrayCopy);
  };

  const onCloseImportAssignment = (item?: XactModel) => {
    setIsAssignmentModalOpen(false);
    setXactTransactionId(item?.xactTransactionId || null);

    if (!item) {
      return;
    }

    reset({
      projAddr: item.lossAddressStreet,
      projProv: item.lossAddressState,
      projCity: item.lossAddressCity,
      projZip: item.lossAddressPostal,
      claimNumber: item.claimNumber,
      contactName: item.contactName,
      lossDate: item.lossDate,
      lossType: item.typeOfLoss,
    });

    const phase = item.isEmergency ? t('emergency') : t('repair');
    setPhaseList([
      {
        id: Date.now(),
        phase,
        phaseId: item.isEmergency ? DefaultPhaseIds.EMERGENCY : DefaultPhaseIds.REPAIR,
        phaseCode: item.isEmergency ? DefaultPhaseIds.EMERGENCY : DefaultPhaseIds.REPAIR,
        description: phase,
        estValue: 0,
        xactTransactionId: item.xactTransactionId,
      },
    ]);
  };

  useEffect(() => {
    setValue('projProv', province);
  }, [province, setValue]);

  const shouldSetAdjusterDefaultValue = !adjusterDefaultValue && adjusterOptions[0]?.value && operation !== SaveClaimOperation.EDIT;

  useEffect(() => {
    if (shouldSetAdjusterDefaultValue) {
      setValue('adjCode', adjusterOptions[0].value);
      setAdjusterDefaultValue(true);
    }
  }, [adjusterOptions, setValue, shouldSetAdjusterDefaultValue]);

  const setValuesForForm = useCallback((_claim: any) => {
    if (_claim) {
      setValue('claimNumber', _claim?.claimNumber);
      setValue('contactName', _claim?.contactName || '');
      setValue('contactPhone', _claim?.contactPhone);
      setValue('projAddr', _claim?.projAddr);
      setValue('projCity', _claim?.projCity);
      setValue('projProv', _claim?.projProv);
      setValue('projZip', _claim?.projZip);
      setValue('projName', _claim?.projName);
      setValue('calledIn', _claim?.calledIn);
      setValue('brId', _claim?.claimNumber.slice(0, 2) ?? '');
      setValue('dedAmt', _claim?.dedAmt || '');
      setValue('jobPcoord', _claim?.jobPcoord);
      if (_claim?.lossCat) {
        setValue('lossCat', _claim.lossCat.toString());
        setIsCommercial(_claim?.lossCat === ClaimType.Commercial);
      }
      if (_claim?.verticalId) {
        setValue('verticalId', _claim?.verticalId);
        if (_claim?.verticalId === OTHER_VERTICAL) {
          setIsOtherVertical(true);
          setValue('verticalName', _claim?.verticalName);
        }
      }
      if (_claim?.pcId) {
        setValue('pcId', _claim?.pcId || '');
      } else {
        setValue('pcId', _claim?.constructionManagerId || '');
      }
      setValue('adjCode', _claim?.adjCode);
      setValue('claimCo', _claim?.claimCo);
      setValue('pmgrName', _claim?.pmgrName || '');
      setValue('claimNo', _claim?.claimNo || '');
      setValue('policyNo', _claim?.policyNo || '');
      
      setLossAddressLat(_claim?.lossAddressLat);
      setLossAddressLon(_claim?.lossAddressLon);
      setPhaseList(_claim?.phases);
      setClaimIndx(_claim?.claimIndx);
      setValue('lossDate', _claim?.lossDate ? new Date(_claim?.lossDate).toISOString() : '');
      setValue('jobDOpen', _claim?.jobDateOpen || _claim?.jobDOpen ? new Date(_claim?.jobDateOpen || _claim?.jobDOpen).toISOString() : '');

      setValue('projEmail', _claim?.projEmail || '');
      setValue('yearOfConstruction', _claim?.yearOfConstruction || '');

      const insurerCode = insuranceCompaniesOptions.find((option) => option.label === _claim?.insurer)?.value;
      if (insurerCode) {
        setValue('claimCo', insurerCode);
      }

      const propertyManagerCode = propertyManagerOptions.find((option) => option.label === _claim?.propertyManager)?.value;
      if (propertyManagerCode) {
        setValue('pmgrName', propertyManagerCode);
      }

      const lossTypeCode = lossTypeOptions.find((option) => option.label === _claim?.lossType)?.value;
      if (lossTypeCode) {
        setValue('lossType', lossTypeCode);
      }

      const projectManagerCode = projectManagerOptions.find((option) => option.label === _claim?.pmNameClaim)?.value;
      if (projectManagerCode) {
        setValue('jobPcoord', projectManagerCode);
      }

      const adjusterCode = adjusterOptions.find((option) => option.label === _claim?.adjuster)?.value;
      if (adjusterCode) {
        setValue('adjCode', adjusterCode);
      }

      const verticalId = verticalOptions.find((option) => option.value === _claim?.verticalId)?.value;
      if (verticalId) {
        setValue('verticalId', verticalId);
      }
    }
  }, [
    adjusterOptions,
    insuranceCompaniesOptions,
    lossTypeOptions,
    projectManagerOptions,
    propertyManagerOptions,
    verticalOptions, 
    setValue,
  ]);

  const onClaimDetailsSuccess = async (_data: any) => {
    if (_data && !claim) {
      _data.claim.lossDate = _data.claim?.lossDate?.replace('Z', '') || null;
      _data.claim.jobDateOpen = _data.claim?.jobDateOpen?.replace('Z', '') || null;
      _data.claim.jobDOpen = _data.claim.jobDOpen && _data.claim?.jobDOpen?.replace('Z', '') || null;
      setClaim(_data.claim);
      setOperation(SaveClaimOperation.EDIT);
      setValuesForForm(_data.claim);
      setIsReadOnlyField(true);
    }
  };

  const { isLoading, refetch: refetchClaim } = useClaimDetails(region, isOffline ? 0 : Number(jobIdx), userState, onClaimDetailsSuccess);

  const loadOfflineClaimEnabled = isOffline
    && !claim
    && projectManagerOptions.length > 0
    && lossTypeOptions.length > 0
    && adjusterOptions.length > 0
    && insuranceCompaniesOptions.length > 0
    && propertyManagerOptions.length > 0;

  useEffect(() => {
    if (loadOfflineClaimEnabled) {
      const getAsync = async () => {
        const { claimForm: localClaim, operation: localOperation } = await getClaimStorage(jobIdx, offlineClaim, projectManagerOptions, adjusterOptions, insuranceCompaniesOptions);
        setClaim(localClaim);
        setValuesForForm(localClaim);
        setOperation(localOperation);
        if (localClaim.claimIndx) {
          setIsReadOnlyField(true);
        }
      };
      getAsync();
    }
  }, [
    adjusterOptions,
    insuranceCompaniesOptions,
    jobIdx,
    loadOfflineClaimEnabled,
    offlineClaim,
    projectManagerOptions,
    verticalOptions,
    setValuesForForm,
  ]);

  const { mutateAsync, saving } = useCreateNewClaimMutation();

  const { updateClaim, updating } = useEditClaimMutation();

  const onSubmit = handleSubmit(async (data) => {
    if (!phaseList?.length && !jobIdx) {
      setSaveError(t('phaseIsRequired'));
      return;
    }

    const status = await Network.getStatus();

    if (status.connected && !isOffline) {
      const formClaim = formatDataForm({ data, phaseList, user, claimIndx, lossAddressLat, lossAddressLon });
      let resp: any;
      try {
        await checkTokenExpiration();
        if (jobIdx) {
          // edit, need remove read-only props
          delete formClaim.claimNumber;
          delete formClaim.jobPcoord;
          delete formClaim.brId;
          delete formClaim.status;
          resp = await updateClaim({ regionId: region, claim: formClaim });
        } else {
          resp = await mutateAsync({ regionId: region, claim: formClaim, xactTransactionId });
        }
        if (resp?.saveClaim?.status === ApiStatus.SUCCESS || resp?.updateClaim?.status === ApiStatus.SUCCESS) {
          updateRefetchKey();
          setShowSuccessToast(true);
          // after save at API, store locally
          data.status = ClaimOnlineStatus.AvailableOffline;
          await saveClaim({
            data,
            phaseList,
            user,
            claimIndx,
            lossAddressLat,
            lossAddressLon,
            region,
            xactTransactionId,
            jobIdx: claim?.claimIndx || 0,
            operation: SaveClaimOperation.EDIT,
          });
          refresh();
          refetchClaim();
          setTimeout(() => { navigation.goBack(); }, 1000);
          return;
        }
        throw new Error(resp.saveClaim?.message || t('saveClaimError'));
      } catch (e: any) {
        const msg = getErrorMsg(e);
        const message = msg || t('saveClaimError');
        setSaveError(message);
      }
    } else {
      // offline
      data.status = ClaimOnlineStatus.ToBeSynched;
      setShowSuccessLocallyToast(true);
      await saveClaim({
        data,
        phaseList,
        user,
        claimIndx,
        lossAddressLat,
        lossAddressLon,
        region,
        xactTransactionId,
        jobIdx: claim?.claimIndx || 0,
        operation: operation || SaveClaimOperation.ADD,
      });
      refresh();
      navigation.push('/tabs/jobs');
    }
  });

  const onAutoCompleteOptionSelect = (option?: any) => {
    if (option) {
      setPlaceName('');
      getPlaceDetails(option.placeId, userState).then((response) => {
        setLossAddressLat(response.data?.result?.geometry.location.lat);
        setLossAddressLon(response.data?.result?.geometry.location.lng);
  
        const suggestedCity = response.data?.result?.address_components.find((item: any) => item.types[0] === 'Locality')?.long_name;
        const suggestedPostalCode = response.data?.result?.address_components.find((item: any) => item.types[0] === 'Postal_Code')?.long_name;
        const suggestedProvince = response.data?.result?.address_components.find((item: any) => item.types[0] === 'Administrative_Area_Level_1')?.short_name;
  
        setValue('projCity', suggestedCity);
        setProvince(suggestedProvince);
        setValue('projZip', suggestedPostalCode);
        setValue('projAddr', option.description);
        clearErrors('projCity');
        clearErrors('projProv');
      });
    }
  };

  const addPhaseClick = () => {
    setIsPhaseModalOpen(true);
  };

  const saveForm = () => {
    if (!isValid) {
      toast.error(t('mandatoryFieldsNotValid'));
    }
    document.getElementById('addJobForm')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const handleLossCatChange = (value: string) => {
    if (value === ClaimType.Commercial.toString()) {
      setIsVerticalVisible(true);
    } else {
      setIsVerticalVisible(false);
      setValue('verticalId', undefined);
      setValue('verticalName', undefined);
    }
  };

  const { verticalId } = watch();
  useEffect(() => {
    if (verticalId === OTHER_VERTICAL) {
      setIsOtherVertical(true);
    } else {
      setIsOtherVertical(false);
    }
  }, [verticalId]);

  useEffect(() => {
    if (isCommercial) {
      setIsVerticalVisible(true);
    } else {
      setIsOtherVertical(false);
    }
  }, [setIsVerticalVisible, isCommercial]);

  return {
    navigation,
    onSubmit,
    setIsAssignmentModalOpen,
    claim,
    register,
    errors,
    control,
    brancheOptions,
    projectManagerOptions,
    constructionManagerOptions,
    isReadOnlyField,
    adjusterOptions,
    verticalOptions,
    insuranceCompaniesOptions,
    propertyManagerOptions,
    lossTypeOptions,
    onAutoCompleteOptionSelect,
    suggestions,
    onAddressChange,
    closeIcon,
    phaseList,
    isPhaseModalOpen,
    setIsPhaseModalOpen,
    deleteItem,
    isAssignmentModalOpen,
    addPhaseItem,
    saving,
    updating,
    onCloseImportAssignment,
    addPhaseClick,
    isLoading,
    saveError,
    setSaveError,
    showSuccessToast,
    setShowSuccessToast,
    showSuccessLocallyToast,
    setShowSuccessLocallyToast,
    operation,
    isSendToXAEnabled,
    isCommercial,
    isOtherVertical,
    region,
    maxDate,
    saveForm,
    handleLossCatChange,
    isVerticalVisible,
  };
};

export default AddJobViewModel;