import React, { useState } from 'react';
import {
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonToast,
  IonToolbar,
  useIonActionSheet,
  useIonAlert,
  useIonRouter,
} from '@ionic/react';
import { arrowBack, filterOutline, closeOutline } from 'ionicons/icons';
import { t } from 'i18next';
import FosDocItem from '../atom/FosDocItem';
import './UserExpensesForApproval.css';
import addCurrencyMask from '../utils/CurrencyMask';
import SearchInputWithValue from '../atom/SearchInputWithValue';
import UserExpensesForApprovalViewModel from './UserExpensesForApprovalViewModel';
import FilterModal from '../organism/FilterModal';
import FosSelectBigListItem from '../atom/FosSelectBigListItem';
import ButtonIcon from '../atom/ButtonIcon';
import { ExpenseStatusType } from './ExpenseStatus';
import { ExpenseMethod } from '../pages/ExpenseMethod';
import { useAuth } from '../hooks/authContext';
import useApproveExpense from '../hooks/approveExpense';
import useDeclineExpense from '../hooks/declineExpense';
import { ApiStatus } from '../pages/helper/Const';

const UserExpensesForApproval: React.FC = () => {

  const {
    userExpenseSummaryForApprovalFiltered,
    userExpenseListForApprovalFiltered,
    isFetching,
    isFetchingExpenses,
    expenseMethodId,
    selectedExpenseMethodName,
    searchTerm,
    expenseForApprovalFilterModal,
    expenseStatusListOptions,
    filterByStatus,
    clearFilter,
    showResults,
    setFilterByStatus,
    onSearchChange,
    navigateToUserExpenseCategory,
    backToCategories,
    refetchExpenseList,
  } = UserExpensesForApprovalViewModel();

  const [presentAlert] = useIonAlert();
  const navigation = useIonRouter();
  const [present] = useIonActionSheet();
  const [loading, setLoading] = useState(false);
  const [declineError, setDeclineError] = useState('');

  const { userState } = useAuth()!;
  const { mutateAsync: approveExpense } = useApproveExpense(userState);
  const { mutateAsync: declineExpense } = useDeclineExpense(userState);

  enum ExpenseAction {
    APPROVE = 'APPROVE',
    DECLINE = 'DECLINE',
    VIEW = 'VIEW',
    CANCEL = 'Cancel',
  }
  const expensesActionSheetButtons = [
    {
      text: t('approve'),
      statuses: [ExpenseStatusType.ReleasedToApprover],
      data: {
        action: ExpenseAction.APPROVE,
      },
    },
    {
      text: t('decline'),
      statuses: [ExpenseStatusType.ReleasedToApprover],
      data: {
        action: ExpenseAction.DECLINE,
      },
    },
    {
      text: t('view'),
      statuses: [
        ExpenseStatusType.Pending,
        ExpenseStatusType.ReleasedToApprover,
        ExpenseStatusType.ReleasedToFinance,
        ExpenseStatusType.Exported,
        ExpenseStatusType.ApprovedByFinance,
        ExpenseStatusType.DeclinedByFinance,
      ],
      data: {
        action: ExpenseAction.VIEW,
      },
    },
    {
      text: t('cancel'),
      role: 'destructive',
      statuses: [
        ExpenseStatusType.Pending,
        ExpenseStatusType.ReleasedToApprover,
        ExpenseStatusType.ReleasedToFinance,
        ExpenseStatusType.Exported,
        ExpenseStatusType.ApprovedByFinance,
        ExpenseStatusType.DeclinedByFinance,
      ],
      data: {
        action: ExpenseAction.CANCEL,
      },
    },
  ];

  const handleError = (err: string) => {
    presentAlert({
      header: t('warning'),
      message: err,
    });
  };

  const approveExpenseInternal = async (expenseId: number) => {
    setLoading(true);
    if (expenseId) {
      const result = await approveExpense(expenseId);
      if (result?.approveExpense?.status === ApiStatus.FAILURE) {
        handleError(result?.approveExpense?.message);
      } else {
        refetchExpenseList();
      }
    }
    setLoading(false);
  };

  const declineExpenseInternal = async (expenseId: number, reason: string) => {
    if (!reason) {
      setDeclineError(t('reasonRequired'));
      return;
    }
    setLoading(true);
    if (expenseId) {
      const result = await declineExpense({ expenseId, reason });
      if (result?.deleteExpense?.status === ApiStatus.FAILURE) {
        handleError(result?.declineExpense?.message);
      } else {
        refetchExpenseList();
      }
    }
    setLoading(false);
  };

  const actionSheetForApprovalExpenses = (expenseId: number, expenseMethodId: number, status: number, childExpenseId?: number) => {
    const statusEnum = status as ExpenseStatusType;
    const actionButtons = expensesActionSheetButtons.filter(a => a.statuses?.some(s => s === statusEnum));

    present({
      header: 'Expense',
      cssClass: 'custom-action-sheet',
      buttons: actionButtons,
      onDidDismiss: ({ detail }) => {
        switch (detail.data?.action) {
          case ExpenseAction.VIEW: {
            if (expenseMethodId === ExpenseMethod.MILEAGE) {
              navigation.push(`/mileage-expense/${expenseId}`);
            } else if (expenseMethodId === ExpenseMethod.CREDIT_CARD) {
              navigation.push(`/credit-card-expense/${expenseId}`);
            } else {
              navigation.push(`/expense/${expenseId}`);
            }

            break;
          }
          case ExpenseAction.APPROVE: {
            presentAlert({
              header: t('confirm'),
              message: t('confirmApproveExpense'),
              buttons: [
                t('cancel'),
                {
                  handler: () => approveExpenseInternal(Number(childExpenseId ?? expenseId)),
                  text: t('approve'),
                },
              ],
            });

            break;
          }
          case ExpenseAction.DECLINE: {
            presentAlert({
              header: t('confirm'),
              message: t('confirmDeclineExpense'),
              inputs: [{
                name: 'reason',
                placeholder: t('reasonForDeclineExpense'),
              }],

              buttons: [
                t('cancel'),
                {
                  handler: (alert: any) => declineExpenseInternal(Number(childExpenseId ?? expenseId), alert.reason),
                  text: t('decline'),
                },
              ],
            });

            break;
          }
          case ExpenseAction.CANCEL:
          default: break;
        }
      },
    });
  };

  return (
    <>
      {!!expenseMethodId && <IonToolbar className='user-expenses-for-approval-custom-ion-toolbar'>
        <div className="ion-padding-horizontal user-expenses-for-approval-toolbar">
          <SearchInputWithValue value={searchTerm} id="search-user-expenses-for-approval-bar" debounce={1000} onSearch={onSearchChange} />
          <ButtonIcon id="expenseForApprovalFilterModal" icon={filterOutline} hide={!expenseMethodId} />
        </div>
        <div role="presentation" className='user-expenses-for-approval-category'>
          <IonIcon onClick={() => backToCategories()} slot="start" className='arrow-back-icon' icon={arrowBack} />
          <IonLabel className='user-expenses-for-approval-category-label'
          >
            {selectedExpenseMethodName} - {expenseStatusListOptions.filter(s => s.value === filterByStatus)[0]?.label}
          </IonLabel>
        </div>
      </IonToolbar>}
      <div className={`user-expense-for-approval-content ${expenseMethodId ? 'user-expense-for-approval-content-details' : ''}`}>
        {!!expenseMethodId && <FilterModal
          ref={expenseForApprovalFilterModal}
          trigger="expenseForApprovalFilterModal"
          onClear={clearFilter}
          onClose={() => expenseForApprovalFilterModal.current?.dismiss()}
          onShowResults={showResults}
        >
          <IonList>
            <div className='user-expenses-div-filter'>
              <div className='user-expenses-ion-label'>
                <IonLabel>
                  {t('status').toString()}
                </IonLabel>
              </div>
            </div>
            <FosSelectBigListItem
              id="filterUserExpenseByStatus"
              onChange={(value: string) => {
                setFilterByStatus(value || filterByStatus);
                localStorage.setItem('userExpensesForApprovalStatusId', value || filterByStatus);
              }}
              value={filterByStatus.toString()}
              name='filterByStatus'
              label={t('status')}
              options={expenseStatusListOptions}
            />
          </IonList>
        </FilterModal>}
        {userExpenseListForApprovalFiltered && expenseMethodId ? <IonList lines='none'>
          {userExpenseListForApprovalFiltered.map((userExpense) => (
            expenseMethodId === ExpenseMethod.MILEAGE ?
              <div><FosDocItem
                key={userExpense.expense.id}
                subheading={userExpense.employeeName}
                label={`${userExpense.expense.id.toString()} - ${userExpense.expense.comments}`}
                secondLabel={addCurrencyMask(userExpense.expense.totalAmount)}
                onItemClick={() => actionSheetForApprovalExpenses(userExpense.expense.id, expenseMethodId, userExpense.expense.status.id)}
              /></div>
              :
              <IonAccordionGroup key={userExpense.expense.id}>
                <IonAccordion key={userExpense.expense.id} value={userExpense.expense.id.toString()}>
                  <IonItem slot="header" color="light">
                    <div role="presentation" className="fos-doc-label-row">
                      <div className='fos-doc-item-label'>
                        <IonLabel>
                          {`${userExpense.expense.id.toString()} - ${expenseMethodId === ExpenseMethod.CREDIT_CARD ? userExpense.expense.merchant : userExpense?.expense?.comments}`}
                        </IonLabel>
                        <IonLabel className='fos-doc-item-second-label'>
                          {addCurrencyMask(userExpense.expense.totalAmount)}
                        </IonLabel>
                      </div>
                      <div className='fos-doc-item-subheading-label'>
                        <IonLabel>{userExpense.employeeName}</IonLabel>
                      </div>
                    </div>
                    <IonLabel>{userExpense.expense.id} - {userExpense.employeeName} - {expenseMethodId === ExpenseMethod.CREDIT_CARD ? userExpense.expense.merchant : userExpense?.expense?.comments}</IonLabel>
                  </IonItem>
                  <div className="ion-padding-top ion-padding-bottom" slot="content">
                    {userExpense.expense.isParent ? userExpense.childExpenses.map((childExp: any) => {
                      const childExpense = childExp.expense;
                      return <div key={childExpense.id}>
                        <FosDocItem
                          subheading={userExpense.employeeName}
                          label={`${childExpense.id.toString()} - ${expenseMethodId === ExpenseMethod.CREDIT_CARD ? childExpense.merchant : childExpense.comments}`}
                          secondLabel={addCurrencyMask(childExpense.totalAmount)}
                          onItemClick={() => actionSheetForApprovalExpenses(userExpense.expense.id, expenseMethodId, childExpense.status.id, childExpense.id)}
                        />
                      </div>;
                    }) :
                      <div>
                        <FosDocItem
                          key={userExpense.expense.id}
                          subheading={userExpense.employeeName}
                          label={`${userExpense.expense.id.toString()} - ${expenseMethodId === ExpenseMethod.CREDIT_CARD ? userExpense?.expense?.merchant : userExpense?.expense?.comments}`}
                          secondLabel={addCurrencyMask(userExpense.expense.totalAmount)}
                          onItemClick={() => actionSheetForApprovalExpenses(userExpense.expense.id, expenseMethodId, userExpense.expense.status.id)}
                        />
                      </div>}
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
          ))}
          {userExpenseListForApprovalFiltered.length === 0 && !(isFetching || isFetchingExpenses) &&
            <p className="ion-text-center">{t('noRecord').toString()}</p>
          }
        </IonList> : <IonList lines='none'>
          {userExpenseSummaryForApprovalFiltered.map((userExpense) => (
            <div key={userExpense.id}>
              <FosDocItem
                label={userExpense.name}
                secondLabel={addCurrencyMask(userExpense.expenses)}
                onItemClick={() => navigateToUserExpenseCategory(userExpense.id)}
                onArrowClick={() => { }}
              />
            </div>
          ))}
        </IonList>}
        <IonLoading isOpen={isFetching || isFetchingExpenses || loading} message={t('loading')} duration={5000} />
        <IonToast
          isOpen={!!declineError}
          duration={3000}
          message={declineError}
          buttons={[{ role: 'cancel', icon: closeOutline }]}
          onDidDismiss={() => { setDeclineError(''); }}
        />
      </div>
    </>
  );
};

export default UserExpensesForApproval;