/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonBackButton,
  useIonActionSheet,
  useIonAlert,
  IonItem,
  IonList,
  IonLoading,
  IonToast,
} from '@ionic/react';
import './AddExpense.css';
import { menuOutline, trashOutline, createOutline, closeOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { AxiosResponse } from 'axios';
import { SimpleExpenseFormData, createSimpleExp } from '../organism/ExpSimpleExpenseForm';
import { SimpleExpenseFormFull, SimpleExpenseFormFullData, SimpleExpenseFormFullErrors, createSimpleExpFull, getSimpleExpFullErrors } from '../organism/ExpSimpleExpenseFormFull';
import { ExpenseBaseForm, ExpenseBaseFormData, ExpenseBaseFormErrors, createBaseExp } from '../organism/ExpBaseExpenseForm';
import addCurrencyMask from '../utils/CurrencyMask';
import SimpleExpenseFormModal from '../organism/ExpSimpleExpenseFormModal';
import { JobRelExpenseFormData, createJobRelExp } from '../organism/ExpJobRelExpenseForm';
import {
  JobRelExpenseFormFull,
  JobRelExpenseFormFullData,
  JobRelExpenseFormFullErrors,
  createJobRelExpFull,
  getJobRelExpenseFullErrors,
  saveJobRelExpFull,
} from '../organism/ExpJobRelExpenseFormFull';
import JobRelExpenseFormModal from '../organism/ExpJobRelExpenseFormModal';
import { GUID } from '../graphql/GetUserProfileInfo';
import { useAuth } from '../hooks/authContext';
import useExpenseDetails from '../hooks/expDetails';
import useGetPayrollDetails from '../hooks/useGetPayrollDetails';
import useDeleteExpense from '../hooks/deleteExpense';
import { ApiStatus } from './helper/Const';
import { ExpenseReceiptDtoInput } from '../hooks/expSaveExpense';
import FormItemDivider from '../atom/FormItemDivider';
import { ExpenseMethod } from './ExpenseMethod';
import { ExpenseStatusType } from '../molecule/ExpenseStatus';
import useApproveExpense from '../hooks/approveExpense';
import useDeclineExpense from '../hooks/declineExpense';

interface RouteParams {
  expenseId: string;
}

const AddExpense: React.FC = () => {
  const { userState } = useAuth()!;
  const [expenses, setExpenses] = useState<(SimpleExpenseFormData | JobRelExpenseFormData)[]>([]);
  const { expenseId } = useParams<RouteParams>();
  const { data: payrollDetails } = useGetPayrollDetails(userState);
  const { mutateAsync: approveExpense } = useApproveExpense(userState);
  const { mutateAsync: declineExpense } = useDeclineExpense(userState);
  const { t } = useTranslation();

  const [presentActionSheet] = useIonActionSheet();
  const [presentAlert] = useIonAlert();
  const [formMode, setFormMode] = useState('simple');
  const [showSimpleExpFormModal, setShowSimpleExpFormModal] = useState(false);
  const [incompleteExpense, setIncompleteExpense] = useState<boolean>(true);
  const [showJobRelExpFormModal, setShowJobRelExpFormModal] = useState(false);
  const [expenseMethodId, setExpenseMethodId] = useState(ExpenseMethod.OUT_OF_POCKET);
  const [simpleExpEdit, setSimpleExpEdit] = useState<SimpleExpenseFormData | null>(null);
  const [jobRelExpEdit, setJobRelExpEdit] = useState<JobRelExpenseFormData | null>(null);
  const [simpleExpFull, setSimpleExpFull] = useState<SimpleExpenseFormFullData | null>(expenseId ? null : createSimpleExpFull(0, ''));
  const [jobRelExpFull, setJobRelExpFull] = useState<JobRelExpenseFormFullData | null>(expenseId ? null : createJobRelExpFull(0, ''));
  const [baseExpense, setBaseExpense] = useState<ExpenseBaseFormData>(createBaseExp());
  const [loading, setLoading] = useState(false);
  const [simpleExpFullErrors, setSimpleExpFullErrors] = useState<SimpleExpenseFormFullErrors>({});
  const [jobRelExpFullErrors, setJobRelExpFullErrors] = useState<JobRelExpenseFormFullErrors>({});
  const [baseExpenseErrors, setBaseExpenseErrors] = useState<ExpenseBaseFormErrors>({});
  const [isEditMode, setIsEditMode] = useState(true);
  const [isApprovalMode, setIsApprovalMode] = useState(false);
  const { mutateAsync: deleteExpense } = useDeleteExpense(userState);
  const [saveError, setSaveError] = useState('');
  const [statusId, setStatusId] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    if (payrollDetails && !expenseId) {
      const branchId = (payrollDetails?.payrollDetails?.user?.branchId) || 0;
      const regionId = (payrollDetails?.payrollDetails?.userDetail?.province) || '';

      setSimpleExpFull(createSimpleExpFull(branchId, regionId));
      setJobRelExpFull(createJobRelExpFull(branchId, regionId));
    }
  }, [payrollDetails, expenseId]);

  useEffect(() => {
    const url = window.location.href.toString();
    if (url.includes('credit-card-expense')) {
      setExpenseMethodId(ExpenseMethod.CREDIT_CARD);
    } else if (url.includes('mileage-expense'))
      setExpenseMethodId(ExpenseMethod.MILEAGE);
    else {
      setExpenseMethodId(ExpenseMethod.OUT_OF_POCKET);
    }
  }, []);

  const defineBaseExpense = (baseObj: JobRelExpenseFormFullData | SimpleExpenseFormFullData | null) => {
    if (!baseObj) return;

    const baseExp = createBaseExp();
    if (baseObj) {
      baseExp.currency = baseObj.currency;
      baseExp.creditCardId = baseObj.creditCardId;
      baseExp.creditCardNumber = baseObj.creditCardNumber;
      baseExp.date = baseObj.date;
      baseExp.location = baseObj.location;
      baseExp.merchant = baseObj.merchant;
      baseExp.receipts = baseObj.receipts;
      baseExp.tip = baseObj.tip;
      baseExp.totalAmount = baseObj.totalAmount;
      baseExp.receipts = baseObj.receipts;
      baseExp.receiptsData = baseObj.receiptsData;
      setBaseExpense(baseExp);
    }
  };

  const onSuccessDetail = (data: any) => {
    const expense = data?.expense?.expense;
    expense.employeeName = data?.expense?.employeeName;
    const taxes = data?.expense?.expenseTaxes;

    function getChildExpenses(expense: any): (SimpleExpenseFormData | JobRelExpenseFormData)[] {
      if (!expense.childExpenses) return [];

      return expense.childExpenses.map((childExpense: any) => {
        const child = childExpense.expense;
        if (childExpense.expenseClaims?.length > 0) {
          const exp: JobRelExpenseFormData = { ...child };
          exp.id = child.id;
          exp.taxes = childExpense.expenseTaxes;
          exp.branch = child.branchId;
          exp.costType = childExpense.expenseClaims[0]?.jobCostTypeId;
          exp.category = childExpense.expenseClaims[0]?.jobCostId;
          exp.department = childExpense.expenseClaims[0]?.departmentId;
          exp.projectId = childExpense.expenseClaims[0]?.phaseIndx;
          exp.projectIdParent = childExpense.expenseClaims[0]?.claimIndx;
          return exp;
        }

        const exp: SimpleExpenseFormData = { ...child };
        exp.id = child.id;
        exp.taxes = childExpense.expenseTaxes;
        exp.branch = child.branchId;
        exp.category = child.gpcategoryId;
        exp.department = child.gpdepartmentId;
        return exp;
      });
    }
    if (expense) {
      setIncompleteExpense(!data.expense.expenseClaims[0]?.departmentId && !expense.gpdepartmentId);
      const incompleteExpense = !data.expense.expenseClaims[0]?.departmentId && !expense.gpdepartmentId;
      if (data?.expense?.expenseClaims?.length > 0 || incompleteExpense) {
        if (data?.expense?.expenseClaims?.length > 0 && !incompleteExpense) {
          setFormMode('jobRelated');
        }
        const exp: JobRelExpenseFormFullData = { ...expense };
        exp.id = expense.id;
        exp.date = expense.dateExpense;
        exp.location = expense.province;
        exp.creditCardId = data.expense.expenseUserCreditCards[0]?.id;
        exp.creditCardNumber = data.expense.expenseUserCreditCards[0]?.cardNumber;
        exp.taxes = taxes;
        exp.branch = expense.branchId;
        exp.costType = data.expense.expenseClaims[0]?.jobCostTypeId;
        exp.category = data.expense.expenseClaims[0]?.jobCostId;
        exp.department = data.expense.expenseClaims[0]?.departmentId;
        exp.currency = expense.currencyId.toString();
        exp.projectId = data.expense.expenseClaims[0]?.phaseIndx;
        exp.projectIdParent = data.expense.expenseClaims[0]?.claimIndx;
        exp.receipts = data.expense.expenseReceipts?.filter((receipt: ExpenseReceiptDtoInput) => !receipt.deleted);

        const childExpenses: (SimpleExpenseFormData | JobRelExpenseFormData)[] = getChildExpenses(data?.expense);
        setExpenses(childExpenses);
        setJobRelExpFull(exp);
        if (childExpenses.length > 0) {
          defineBaseExpense(exp);
        }
      }
      if (!data?.expense?.expenseClaims?.length || incompleteExpense) {
        if (!data?.expense?.expenseClaims?.length && !incompleteExpense) {
          setFormMode('simple');
        }
        const exp: SimpleExpenseFormFullData = { ...expense };
        exp.id = expense.id;
        exp.date = expense.dateExpense;
        exp.location = expense.province;
        exp.creditCardId = data.expense.expenseUserCreditCards[0]?.id;
        exp.creditCardNumber = data.expense.expenseUserCreditCards[0]?.cardNumber;
        exp.taxes = taxes;
        exp.branch = expense.branchId;
        exp.category = expense.gpcategoryId;
        exp.department = expense.gpdepartmentId;
        exp.currency = expense.currencyId.toString();
        exp.receipts = data.expense.expenseReceipts?.filter((receipt: ExpenseReceiptDtoInput) => !receipt.deleted);

        const childExpenses: (SimpleExpenseFormData | JobRelExpenseFormData)[] = getChildExpenses(data?.expense);
        setExpenses(childExpenses);
        setSimpleExpFull(exp);
        if (childExpenses.length > 0) {
          defineBaseExpense(exp);
        }
      }

      const pendingState = 1;
      const waitingForApproval = 2;

      let editAllowed = data?.expense?.expense.statusId === pendingState;
      if (data.expense.childExpenses?.length > 0) {
        editAllowed = data.expense.childExpenses.some((e: any) => e.expense.statusId === pendingState);
      }

      const userGuid = window.localStorage.getItem(GUID)!;
      if (expense.employeeGuid !== userGuid) {
        editAllowed = false;
        if (data?.expense?.expense.statusId === waitingForApproval) {
          setIsApprovalMode(true);
        }
      }

      setIsEditMode(editAllowed);
      setStatusId(expense.statusId);
    }
  };

  useExpenseDetails(expenseId ? Number(expenseId) : 0, onSuccessDetail);

  const handleError = (err: string) => {
    presentAlert({
      header: t('warning'),
      message: err,
    });
  };

  const handleSaveResponse = (res: AxiosResponse) => {
    if (res.data?.errors?.length) {
      handleError(res.data.errors[0]);
    } else {
      history.goBack();
    }
  };

  async function validateExpenses(expenseList: any[], hasErrors: boolean) {
    if (expenseMethodId === ExpenseMethod.CREDIT_CARD) {
      const parentExpense = expenseList.findIndex(e => e.isParent);
      if (parentExpense >= 0) {
        const totalAmount = Number(expenseList[parentExpense].totalAmount);
        const totalAmountChilExpenses = expenseList.reduce((total: number, e: any) => total + Number(e.totalAmount), 0) - totalAmount;

        if (totalAmount !== totalAmountChilExpenses) {
          setSaveError(t('creditCardTotalCannotChange'));
          setLoading(false);
          hasErrors = true;
          return hasErrors;
        }
      }
    }

    for (const e of expenseList.filter(e => e.statusId === ExpenseStatusType.Pending)) {
      const { isParent } = e;
      if ('projectId' in e) {
        const errs = await getJobRelExpenseFullErrors(e, t);
        if (isParent && Object.values(errs).length) {
          setBaseExpenseErrors(errs);
          setLoading(false);
          hasErrors = true;
          break;
        }

        setJobRelExpFullErrors(errs);

        if (Object.values(errs).length) {
          if (expenseList.length > 1) {
            setSaveError(t('expenseHasErrors'));
          }
          setLoading(false);
          hasErrors = true;
          break;
        }
      } else {
        const errs = await getSimpleExpFullErrors(e, t);
        if (isParent && Object.values(errs).length) {
          setBaseExpenseErrors(errs);
          setLoading(false);
          hasErrors = true;
          break;
        }

        setSimpleExpFullErrors(errs);

        if (Object.values(errs).length) {
          if (expenseList.length > 1) {
            setSaveError(t('expenseHasErrors'));
          }
          setLoading(false);
          hasErrors = true;
          break;
        }
      }
    }

    return hasErrors;
  }

  const handleSaveSimpleExpFull = async (saveAndRelease: Boolean) => {
    if (simpleExpFull) {
      try {
        const userGuid = window.localStorage.getItem(GUID)!;
        setLoading(true);

        let expenseList = [simpleExpFull] as any[];

        if (expenses.length > 0) {
          expenseList[0].receipts = baseExpense.receipts;
          expenseList[0].receiptsData = baseExpense.receiptsData;
          expenseList[0].date = baseExpense.date;
          expenseList[0].location = baseExpense.location;
          expenseList[0].merchant = baseExpense.merchant;
          expenseList[0].currency = baseExpense.currency;
          expenseList[0].totalAmount = baseExpense.totalAmount;
          expenseList[0].tip = baseExpense.tip;
          expenseList[0].isParent = true;

          expenseList = expenseList.concat(expenses.filter((e: any) => !e.statusId || e.statusId === ExpenseStatusType.Pending).map(e => ({ ...e, ...baseExpense, totalAmount: e.totalAmount } as any)));
          expenseList = expenseList.concat(expenses.filter((e: any) => e.statusId && e.statusId !== ExpenseStatusType.Pending));
        }

        let hasErrors = false;
        hasErrors = await validateExpenses(expenseList, hasErrors);

        if (hasErrors) {
          return;
        }

        const res = await saveJobRelExpFull(userGuid, userState, expenseList, saveAndRelease, expenseMethodId);
        handleSaveResponse(res);
      } catch (ex: any) {
        const msg = ex?.response?.data?.ErrorCode === 400 ? t('errorXSS') : '';
        setSaveError(msg);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSaveJobRelExpFull = async (saveAndRelease: Boolean) => {
    if (jobRelExpFull) {
      try {
        const userGuid = window.localStorage.getItem(GUID)!;
        setLoading(true);

        let expenseList = [jobRelExpFull];

        if (expenses.length > 0) {
          expenseList[0].receipts = baseExpense.receipts;
          expenseList[0].receiptsData = baseExpense.receiptsData;
          expenseList[0].date = baseExpense.date;
          expenseList[0].location = baseExpense.location;
          expenseList[0].merchant = baseExpense.merchant;
          expenseList[0].currency = baseExpense.currency;
          expenseList[0].totalAmount = baseExpense.totalAmount;
          expenseList[0].tip = baseExpense.tip;

          expenseList = expenseList.concat(expenses.filter((e: any) => !e.statusId || e.statusId === ExpenseStatusType.Pending).map(e => ({ ...e, ...baseExpense, totalAmount: e.totalAmount } as any)));
          expenseList = expenseList.concat(expenses.filter((e: any) => e.statusId && e.statusId !== ExpenseStatusType.Pending) as any[]);
        }

        let hasErrors = false;
        hasErrors = await validateExpenses(expenseList, hasErrors);

        if (hasErrors) {
          return;
        }

        const res = await saveJobRelExpFull(userGuid, userState, expenseList, saveAndRelease, expenseMethodId);
        handleSaveResponse(res);
      } catch (ex: any) {
        const msg = ex?.response?.data?.ErrorCode === 400 ? t('errorXSS') : '';
        setSaveError(msg);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSave = async () => {
    if (formMode === 'simple' && simpleExpFull) {
      handleSaveSimpleExpFull(false);
    }

    if (formMode === 'jobRelated' && jobRelExpFull) {
      handleSaveJobRelExpFull(false);
    }
  };

  const handleSaveAndRelease = () => {
    if (formMode === 'simple' && simpleExpFull) {
      handleSaveSimpleExpFull(true);
    }

    if (formMode === 'jobRelated' && jobRelExpFull) {
      handleSaveJobRelExpFull(true);
    }
  };
  const deleteExpenseInternal = async () => {
    setLoading(true);
    if (expenseId) {
      const result = await deleteExpense(+expenseId);
      if (result?.deleteExpense?.status === ApiStatus.FAILURE) {
        handleError(result?.deleteExpense?.message);
      } else {
        history.goBack();
      }
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    presentAlert({
      header: t('confirm'),
      message: t('confirmDeleteExpense'),
      buttons: [
        t('cancel'),
        {
          handler: deleteExpenseInternal,
          text: t('delete'),
        },
      ],
    });

  };

  const handleAddSplitClick = () => {
    const baseObj = formMode === 'simple' ? simpleExpFull : jobRelExpFull;
    if (!baseObj?.location) {
      presentAlert({
        header: t('warning'),
        message: t('splitWithoutLocation'),
        buttons: [t('ok')],
      });
      return;
    }

    if (!baseObj?.date) {
      presentAlert({
        header: t('warning'),
        message: t('splitWithoutDate'),
        buttons: [t('ok')],
      });
      return;
    }

    if (!baseObj?.totalAmount) {
      presentAlert({
        header: t('warning'),
        message: t('splitWithoutTotalAmount'),
        buttons: [t('ok')],
      });
      return;
    }

    presentAlert({
      header: t('split'),
      subHeader: t('typeAndAmountOfExpense'),
      inputs: [{ type: 'number' }],
      buttons: [
        {
          text: t('simpleExpense'),
          role: 'simpleExpense',
          handler: data => +data[0] > 0,
        },
        {
          text: t('jobRelatedExpense'),
          role: 'jobRelExpense',
          handler: data => +data[0] > 0,
        },
        {
          text: t('cancel'),
          role: 'cancel',
        },
      ],
      onDidDismiss: (event) => {
        const splitValue = event.detail.data?.values[0];
        if (!splitValue) return;

        if (!expenses.length) {
          defineBaseExpense(formMode === 'simple' ? simpleExpFull : jobRelExpFull);
        }

        if (event.detail.role === 'simpleExpense') {
          setSimpleExpEdit(createSimpleExp({ ...baseExpense, totalAmount: splitValue }));
          setShowSimpleExpFormModal(true);
        }

        if (event.detail.role === 'jobRelExpense') {
          setJobRelExpEdit(createJobRelExp({ ...baseExpense, totalAmount: splitValue }));
          setShowJobRelExpFormModal(true);
        }
      },
    });
  };

  const approveExpenseInternal = async (expenseId: number) => {
    setLoading(true);
    if (expenseId) {
      const result = await approveExpense(expenseId);
      if (result?.approveExpense?.status === ApiStatus.FAILURE) {
        handleError(result?.approveExpense?.message);
      } else {
        history.goBack();
      }
    }
    setLoading(false);
  };

  const declineExpenseInternal = async (expenseId: number, reason: string) => {
    if (!reason) {
      setSaveError(t('reasonRequired'));      
      return;
    }
    setLoading(true);
    if (expenseId) {
      const result = await declineExpense({ expenseId, reason });
      if (result?.deleteExpense?.status === ApiStatus.FAILURE) {
        handleError(result?.declineExpense?.message);
      } else {
        history.goBack();
      }
    }
    setLoading(false);
  };

  const handleMenuClick = () => {
    if (isEditMode) {
      presentActionSheet({
        header: t('chooseAction'),
        buttons: [
          {
            text: t('split'),
            data: {
              action: 'split',
            },
          },
          {
            text: t('save'),
            data: {
              action: 'save',
            },
          },
          {
            text: t('saveAndRelease'),
            data: {
              action: 'saveAndRelease',
            },
          },
          {
            text: t('delete'),
            role: 'destructive',
            data: {
              action: 'delete',
            },
          },
          {
            text: t('cancel'),
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ],
        onDidDismiss: (event) => {
          const action = event.detail?.data?.action;
          if (action === 'split') {
            handleAddSplitClick();
          }

          if (action === 'save') {
            handleSave();
          }

          if (action === 'saveAndRelease') {
            handleSaveAndRelease();
          }

          if (action === 'delete') {
            handleDelete();
          }
        },
      });
    } else if (isApprovalMode) {
      presentActionSheet({
        header: t('chooseAction'),
        buttons: [
          {
            text: t('approve'),
            data: {
              action: 'approve',
            },
          },
          {
            text: t('decline'),
            data: {
              action: 'decline',
            },
          },
          {
            text: t('cancel'),
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ],
        onDidDismiss: (event) => {
          const action = event.detail?.data?.action;
          if (action === 'approve') {
            approveExpenseInternal(Number(expenseId));
          }

          if (action === 'decline') {
            presentAlert({
              header: t('confirm'),
              message: t('confirmDeclineExpense'),
              inputs: [{
                name: 'reason',
                placeholder: t('reasonForDeclineExpense'),
              }],

              buttons: [
                t('cancel'),
                {
                  handler: (alert: any) => declineExpenseInternal(Number(expenseId), alert.reason),
                  text: t('decline'),
                },
              ],
            });
          }
        },
      });
    }
  };
 
  const handleSimpleExpenseFullChange = (id: keyof SimpleExpenseFormFullData, value: any, expense: SimpleExpenseFormFullData) => {
    const exp: SimpleExpenseFormFullData = { ...expense };
    if (id === 'location') {
      exp.taxes = [];
    }

    if (id === 'branch') {
      exp.category = 0;
      exp.department = 0;
    }

    if (id === 'category') {
      exp.department = 0;
    }

    setSimpleExpFull(exp);
    getSimpleExpFullErrors(exp, t).then(errs => {
      const updatedErrors = { ...simpleExpFullErrors };
      updatedErrors[id] = errs[id];
      setSimpleExpFullErrors(updatedErrors);
    });
  };

  const handleJobRelExpenseFullChange = (id: keyof JobRelExpenseFormFullData, value: any, expense: JobRelExpenseFormFullData) => {
    const exp: JobRelExpenseFormFullData = { ...expense };
    setJobRelExpFull(exp);
    getJobRelExpenseFullErrors(exp, t).then(errs => {
      const updatedErrors = { ...jobRelExpFullErrors };
      updatedErrors[id] = errs[id];
      setJobRelExpFullErrors(updatedErrors);
    });
  };

  const handleBaseExpenseChange = (id: keyof ExpenseBaseFormData, value: any, expense: ExpenseBaseFormData) => {
    const exp: ExpenseBaseFormData = { ...expense };
    setBaseExpense(exp);
    getSimpleExpFullErrors({ ...exp, isParent: true } as SimpleExpenseFormFullData, t).then(errs => {
      const updatedErrors = { ...baseExpenseErrors };
      updatedErrors[id] = errs[id];
      setBaseExpenseErrors(updatedErrors);
    });
  };

  const handleExpFormModalClose = (expense?: SimpleExpenseFormData | JobRelExpenseFormData) => {
    if (!expense) return; // user just closed the modal    
    const exps = [...expenses];
    const idx = exps.findIndex(exp => exp.id === expense.id);
    if (idx >= 0) {
      exps.splice(idx, 1, expense);
    } else {
      if (exps.length === 0) {
        const newExpenseTotalAmount = (Number(baseExpense.totalAmount) - Number(expense.totalAmount)).toFixed(2);
        const newExpenseId = Math.trunc(Math.random() * 10000000);
        if (formMode === 'jobRelated' && jobRelExpFull) {
          const newExpense = structuredClone(jobRelExpFull) as JobRelExpenseFormData;
          newExpense.id = newExpenseId;
          newExpense.totalAmount = newExpenseTotalAmount;
          newExpense.taxes = [];
          exps.push(newExpense);
        } else {
          const newExpense = structuredClone(simpleExpFull) as SimpleExpenseFormData;
          newExpense.id = newExpenseId;
          newExpense.totalAmount = newExpenseTotalAmount;
          newExpense.taxes = [];
          exps.push(newExpense);
        }
      }
      exps.push(expense);
    }

    setExpenses(exps);

    if (expenseMethodId !== ExpenseMethod.CREDIT_CARD) {
      const totalAmount = `${exps.reduce((total, exp) => total + Number(exp.totalAmount), 0)}`;
      setBaseExpense({ ...baseExpense, totalAmount });
    }
  };

  const handleDeleteChildExpense = (exp: SimpleExpenseFormData | JobRelExpenseFormData) => {
    if (expenses.length === 2) {
      const lastChild = expenses.filter(e => e.id !== exp.id)[0];

      setExpenses([]);

      const newExp = { ...baseExpense, ...lastChild, totalAmount: baseExpense.totalAmount } as any;
      newExp.taxes = [];
      if (expenseMethodId !== ExpenseMethod.CREDIT_CARD) {
        const totalAmount = `${expenses.reduce((total, exp) => total + Number(exp.totalAmount), 0)}`;
        newExp.totalAmount = totalAmount;
      }

      if ('projectId' in baseExpense) {
        newExp.id = jobRelExpFull?.id;
        setJobRelExpFull(newExp as JobRelExpenseFormFullData);
      } else {
        newExp.id = simpleExpFull?.id;
        setSimpleExpFull(newExp as SimpleExpenseFormFullData);
      }
    } else {
      const newListOfChildren = expenses.filter(e => e.id !== exp.id);
      setExpenses(newListOfChildren);

      if (expenseMethodId !== ExpenseMethod.CREDIT_CARD) {
        const totalAmount = `${newListOfChildren.reduce((total, exp) => total + Number(exp.totalAmount), 0)}`;
        setBaseExpense({ ...baseExpense, totalAmount });
      }
    }
  };

  const handleSimpleExpFormModalClose = (expense?: SimpleExpenseFormData) => {
    setShowSimpleExpFormModal(false);
    handleExpFormModalClose(expense);
  };

  const handleJobRelExpFormModalClose = (expense?: JobRelExpenseFormData) => {
    setShowJobRelExpFormModal(false);
    handleExpFormModalClose(expense);
  };

  const handleSplitExpClick = (expense: SimpleExpenseFormData | JobRelExpenseFormData) => {
    if ('projectId' in expense) {
      // job related
      setJobRelExpEdit(expense);
      setShowJobRelExpFormModal(true);
    } else {
      setSimpleExpEdit(expense);
      setShowSimpleExpFormModal(true);
    }
  };

  return (
    <IonPage className="add-job">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{expenseMethodId === ExpenseMethod.OUT_OF_POCKET ? t('outOfPocket').toString() : t('creditCard').toString()}</IonTitle>
          <IonButtons slot="end">
            <IonButton type="button" disabled={!isEditMode && !isApprovalMode} strong onClick={handleMenuClick}>
              <IonIcon icon={menuOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!!expenses.length && (
          <div className="ion-padding">
            <ExpenseBaseForm
              editMode={isEditMode}
              expenseMethod={expenseMethodId}
              totalAmountReadOnly
              expense={baseExpense}
              errors={baseExpenseErrors}
              statusId={statusId}
              onChange={handleBaseExpenseChange} />
          </div>
        )}
        {(isEditMode || incompleteExpense) && expenses.length === 0 && <div className="ion-padding"> <IonSegment color="primary" value={formMode} onIonChange={(event) => setFormMode(event.detail.value!)}>
          <IonSegmentButton value="simple">
            <IonLabel>{t('simple').toString()}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="jobRelated">
            <IonLabel>{t('jobRelated').toString()}</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        </div>}
        {!expenses.length && (
          formMode === 'simple'
            ? simpleExpFull && <SimpleExpenseFormFull editMode={isEditMode} expenseMethod={expenseMethodId} expense={simpleExpFull} errors={simpleExpFullErrors} onChange={handleSimpleExpenseFullChange} />
            : jobRelExpFull && <JobRelExpenseFormFull editMode={isEditMode} expenseMethod={expenseMethodId} expense={jobRelExpFull} errors={jobRelExpFullErrors} onChange={handleJobRelExpenseFullChange} />
        )}
        {!!expenses.length && (
          <IonList lines="none" className="ion-padding">
            <IonLabel className="ion-padding">
              <h3>{t('childExpenses').toString()}</h3>
            </IonLabel>
            <FormItemDivider />
            {expenses.map((exp: any, i) => (
              <IonItem key={exp.id}>
                <IonLabel>
                  <h3>{exp.comments || '-'}</h3>
                  <p>{addCurrencyMask(+exp.totalAmount)}</p>
                  <p>{t(ExpenseStatusType[exp.statusId || '1'].toString()).toString()}</p>
                </IonLabel>
                <IonButtons slot="end">
                  {i > 0 && isEditMode && (!exp.statusId || exp.statusId === 1) &&
                    <IonButton fill="clear" color="danger" onClick={() => handleDeleteChildExpense(exp)}>
                      <IonIcon icon={trashOutline} key={exp.id} />
                    </IonButton>
                  }
                  {((!exp.statusId || exp.statusId === 1) && isEditMode) &&
                    <IonButton fill="clear" onClick={() => handleSplitExpClick(exp)} >
                      <IonIcon icon={createOutline} key={exp.id} />
                    </IonButton>}
                </IonButtons>
              </IonItem>
            ))}
            <FormItemDivider />
          </IonList>
        )}
        {isEditMode &&
          <IonGrid className="ion-padding">
            <IonRow>
              <IonCol>
                <IonButton fill="outline" expand="block" onClick={handleSave}>
                  {t('save').toString()}
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton fill="outline" expand="block" onClick={handleSaveAndRelease}>
                  {t('saveAndRelease').toString()}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>}
        <SimpleExpenseFormModal
          editMode={isEditMode}
          location={baseExpense.location}
          initialExpense={simpleExpEdit}
          isOpen={showSimpleExpFormModal}
          onClose={handleSimpleExpFormModalClose}
        />
        <JobRelExpenseFormModal
          editMode={isEditMode}
          location={baseExpense.location}
          initialExpense={jobRelExpEdit}
          isOpen={showJobRelExpFormModal}
          onClose={handleJobRelExpFormModalClose}
        />
        <IonToast
          isOpen={!!saveError}
          duration={3000}
          message={saveError}
          buttons={[{ role: 'cancel', icon: closeOutline }]}
          onDidDismiss={() => { setSaveError(''); }}
        />
        <IonLoading isOpen={loading} message={t('saving')} />
      </IonContent>
    </IonPage>
  );
};

export default AddExpense;
