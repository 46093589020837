import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonNote,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToast,
} from '@ionic/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactI18NextChild, useTranslation } from 'react-i18next';
import { closeOutline } from 'ionicons/icons';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMaskito } from '@maskito/react';
import moment from 'moment';
import { useAuth } from '../hooks/authContext';
import FosSelectItem from '../atom/FosSelectItem';
import { Option } from '../utils/Interfaces';

import './ClaimNotesForm.css';
import './ClaimNotificationForm.css';
import useClaimDetails from '../hooks/useClaimDetails';

import { emailRegexNoGlobal, phoneRegex } from '../pages/helper/FormsHelper';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import AutocompleteSearch from '../molecule/AutocompleteSearch';
import getGraphQLClient from '../hooks/graphQLClientUtil';
import GET_EMAIL_SUGGESTIONS, { EmailSuggestion } from '../graphql/GetEmailSuggestions';
import { CustomEmailContact } from './OtherEmailsModal';
import FormItemDivider from '../atom/FormItemDivider';
import FosFormatTextArea from '../atom/FosFormatTextArea';
import toIsoLocalTime from '../utils/IsoLocalTime';
import i18n from '../i18n';
import { PermissionsList, usePermissions } from '../hooks/permissions';

type Props = {
  userName: string;
  claimIndx?: string;
  notification?: any;
  onSubmit: (success: boolean) => void;
  defaultPhone?: string;
};

export enum MessageTemplate {
  ArrivalConfirmation = 'ArrivalConfirmation',
  OnTheWay = 'OnTheWay',
  FreeText = 'FreeText',
  OnTheWayCrew = 'OnTheWayCrew',
}

export enum MessageChannel {
  Email = 'Email',
  SMS = 'SMS',
}

type FormData = {
  claimIndx: number;
  type: string;
  template: string;
  phone: string;
  who: string;
  when: string;
  whenDate: string;
  message: string;
  recipients: string[];
  ccRecipients: string[];
  messageTemplate: MessageTemplate;
  messageChannel: MessageChannel;
  workType: string;
  sendToXa: boolean;
};

const ClaimNotificationForm: React.FC<Props> = ({ userName, claimIndx, notification, onSubmit, defaultPhone }) => {
  const { t } = useTranslation();
  const { userState } = useAuth()!;

  const region = userState.userAuthPayload?.regionId!;

  const [saveError, setSaveError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [claim, setClaim] = useState<any>();
  const [whenDate, setWhenDate] = useState<any>(null);
  const now = new Date();
  const maxDate = toIsoLocalTime(new Date(now.setFullYear(now.getFullYear() + 1)));
  const graphQLClient = getGraphQLClient(userState);
  const [suggestions, setSuggestions] = useState<Array<any>>([]);
  const [ccSuggestions, setCcSuggestions] = useState<Array<any>>([]);
  const [selectedEmails, setSelectedEmails] = useState<Array<CustomEmailContact>>([]);
  const [ccRecipients, setCcRecipients] = useState<Array<CustomEmailContact>>([]);
  const [isInvalidCcEmailAlertOpen, setInvalidCcEmailAlert] = useState(false);
  const [isLinkedToXact, setIsLinkedToXact] = useState(false);
  const [isSendToXact, setIsSendToXact] = useState(false);
  const [phaseIndxWithXA, setPhaseIndxWithXA] = useState(0);
  const tempValue = 'temp';
  const { permissions } = usePermissions();
  const isCrewNotificationsEnabled = permissions.includes(PermissionsList.CrewNotification);
  const generateModal = useRef<HTMLIonModalElement>(null);
  const [showSendToXaModal, setShowSendToXaModal] = useState(false);

  const phoneMask = useMaskito({
    options: {
      mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    },
  });

  const mapToCustomEmailContact = (email: string): CustomEmailContact => ({
    email,
    id: tempValue,
    selected: true,
    fullName: email,
  });

  const onClaimDetailsSuccess = async (_data: any) => {
    if (_data) {
      // use data
      setClaim(_data.claim);
      if (notification?.ccAddresses) {
        const mappedCcRecipients = notification.ccAddresses.map(mapToCustomEmailContact);
        setCcRecipients(mappedCcRecipients);
      }
    }
  };

  const { isFetching } = useClaimDetails(region, !claimIndx ? 0 : Number(claimIndx), userState, onClaimDetailsSuccess);
  const typeOptions: Option[] = [
    { label: t('Email'), value: MessageChannel.Email },
    { label: t('SMS'), value: MessageChannel.SMS },
  ];

  const whenOptions: Option[] = [
    {
      label: '5 minutes',
      value: '5 minutes',
    },
    {
      label: '10 minutes',
      value: '10 minutes',
    },
    {
      label: '15 minutes',
      value: '15 minutes',
    },
    {
      label: '30 minutes',
      value: '30 minutes',
    },
    {
      label: '45 minutes',
      value: '45 minutes',
    },
    {
      label: '1 hour',
      value: '1 hour',
    },
    {
      label: '2 hours',
      value: '2 hours',
    },
  ];
  const workTypeOptions: Option[] = [
    {
      label: t('mitigation'),
      value: t('mitigation'),
    },
    {
      label: t('rebuild'),
      value: t('rebuild'),
    },
    {
      label: t('contents'),
      value: t('contents'),
    },
  ];

  const schema = yup
    .object({
      type: yup.string().required(),
      template: yup.string().required(t('templateRequired')),
      who: yup.string().test(
        'who-set',
        () => t('WhoRequired'),
        () => selectedEmails?.length > 0,
      ),
      when: yup.string().test(
        'when-required',
        () => t('WhenRequired'),
        (value, ctx) => {
          const templateFormValue = ctx.parent.template;
          return !(
            ((templateFormValue === MessageTemplate.OnTheWay && !value)) || 
            ((templateFormValue === MessageTemplate.OnTheWayCrew && !value)) ||
            ((templateFormValue === MessageTemplate.ArrivalConfirmation && !whenDate))
          );
        },
      ),
      message: yup.string(),
      phone: yup.string().when({
        is: (val: string) => val?.length > 0,
        then: yup.string().matches(phoneRegex, { message: t('invalidPhoneNumber') }),
      }),
      workType: yup.string().when({
        is: () => isCrewNotificationsEnabled === true,
        then: yup.string().required(t('workTypeRequired')),
      }),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    control,
    setValue,
    trigger,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      type: MessageChannel.Email,
      template: isCrewNotificationsEnabled
        ? MessageTemplate.OnTheWayCrew
        : MessageTemplate.ArrivalConfirmation },
  });

  const { template, when, type, phone, message, workType } = watch();
  useEffect(()=> {
    if (template !== MessageTemplate.OnTheWayCrew && isCrewNotificationsEnabled) {
      setValue('template', MessageTemplate.OnTheWayCrew);
    }
  }, [isCrewNotificationsEnabled, setValue, template]);
  const isOnTheWaySelected = template === MessageTemplate.OnTheWay;
  // Will need to change the below line if new crew forms are added.
  const isOnTheWayCrewSelected = isCrewNotificationsEnabled;// template === MessageTemplate.OnTheWayCrew;
  const isFreeTextSelected = template === MessageTemplate.FreeText;

  const isArrivalConfirmationSelected = template === MessageTemplate.ArrivalConfirmation;
  const emailInputRef = useRef('');
  const ccEmailInputRef = useRef('');

  useEffect(() => {
    if (claim && Array.isArray(claim.phases)) {
      claim.phases.forEach((element: { linkedToXA: boolean; phaseIndx: number }) => {
        if (element.linkedToXA && !isLinkedToXact) {
          setIsLinkedToXact(true);
        }
      });
    }
  }, [claim, isLinkedToXact, phaseIndxWithXA]);
  
  const formattedNotifactionXact = (recipients?: string, dateTime?: string, sender?: string, messageType?: string, messageBody?: string, ccRecipients?: string[]) => {
    if (isLinkedToXact) {
      let notificationNoteFormattedText;
      if (ccRecipients && ccRecipients.length > 0) {
        notificationNoteFormattedText =
        `Recipients:&nbsp;${  recipients
        }<br>Cc Recipients:&nbsp;${ ccRecipients.join(', ')
        }<br>Date/Time Sent:&nbsp;${  dateTime
        }<br>Sender:&nbsp;${  sender
        }<br>Message Type:&nbsp;${  messageType
        }<br>Message Body:<br>${  messageBody
        }`;
      } else {
        notificationNoteFormattedText =
        `Recipients:&nbsp;${  recipients
        }<br>Date/Time Sent:&nbsp;${  dateTime
        }<br>Sender:&nbsp;${  sender
        }<br>Message Type:&nbsp;${  messageType
        }<br>Message Body:<br>${  messageBody
        }`;
      }
      return notificationNoteFormattedText;
    }
    const noXa = '';
    return noXa;
  };
  

  /*
   * Submit form
   * */
  const onSubmitHandler = handleSubmit(async () => {
    if (template === MessageTemplate.ArrivalConfirmation && new Date(whenDate) < new Date()) {
      setSaveError(t('InvalidWhen'));
      return;
    }
    try {
      setIsLoading(true);
      const communication: any = {};
      let localWhen = '';
      if (
        template === MessageTemplate.OnTheWay ||
        template === MessageTemplate.FreeText ||
        template === MessageTemplate.OnTheWayCrew
      ) {
        localWhen = when;
      } else {
        localWhen = toIsoLocalTime(new Date(when || whenDate));
      }
      const whoProp =
        selectedEmails?.length > 0 ? selectedEmails[0].email : userState.userAuthPayload?.preferredUsername;
      const whoPropEmployeeName = selectedEmails?.length > 0 ? selectedEmails[0].fullName : userName;
      let mappedCcEmails: string[] = [];
      ccRecipients.forEach((i) => {
        mappedCcEmails = [...mappedCcEmails, i.email];
      });
      let currentWorkType = workType;

      if (isCrewNotificationsEnabled && (!currentWorkType || currentWorkType.length < 2)) {
        currentWorkType = t('mitigation');
      }
      communication.templateData = [
        {
          key: 'claimIndx',
          value: claimIndx,
        },
        {
          key: 'claimNumber',
          value: claim.claimNumber,
        },
        {
          key: 'userName',
          value: whoProp,
        },
        {
          key: 'eta',
          value: localWhen,
        },
        {
          key: 'region',
          value: region?.toString(),
        },
        {
          key: 'workType',
          value: currentWorkType,
        },
        {
          key: 'regionId',
          value: region.toString(),
        },
        {
          key: 'phaseIndx',
          value: phaseIndxWithXA.toString(),
        },
      ];
      if (template === MessageTemplate.OnTheWay || template === MessageTemplate.OnTheWayCrew) {
        communication.templateData.push({
          key: 'employeeName',
          value: whoPropEmployeeName,
        });
      }
      // Remove ternary if adding more crew notification options
      communication.messageTemplate = isCrewNotificationsEnabled ? MessageTemplate.OnTheWayCrew : template;
      communication.messageChannel = type;
      communication.message = message;
      communication.workType = currentWorkType;
      communication.sendToXact = isSendToXact;
      const recipient = type === MessageChannel.SMS ? phone : claim?.projEmail;
      if (type === MessageChannel.Email && !emailRegexNoGlobal.test(recipient)) {
        setSaveError(t('invalidNotificationEmailMessage'));
        setIsLoading(false);
        return;
      }
      communication.formattedNotifactionXact = formattedNotifactionXact(recipient, new Date().toLocaleString(), whoProp, type, message, mappedCcEmails);
      communication.recipients = [recipient];
      communication.isFrench = i18n.language === 'fr';
      communication.ccRecipients = mappedCcEmails;
      const restClient = getRestClient(userState, ClientType.FORM);
      const resp = await restClient.post('/common/communication/send-communication', communication);
      if (resp.status === 200) {
        onSubmit(true);
        setShowSendToXaModal(false);
      } else {
        const message = t('saveClaimNotificationError');
        setSaveError(message);
      }
    } catch (e: any) {
      let msg: string;
      if (e?.response?.data?.ErrorCode === 400) {
        msg = t('errorXSS');
      } else {
        msg = e?.response?.data?.Description;
      }
      const message = msg || t('saveClaimNotificationError');
      setSaveError(message);
    }
    setIsLoading(false);
    setShowSendToXaModal(false);
  });

  const getEmailSuggestions = useCallback(
    (emailInput: string, isCc?: boolean) => {
      if (isCc) {
        if (emailInput.length > 0) {
          graphQLClient.request(GET_EMAIL_SUGGESTIONS, { term: emailInput }).then((res) => {
            const emailSuggestions = res?.userEmailsByTerm?.map((emailSuggestion: EmailSuggestion) => ({
              id: emailSuggestion.email,
              description: emailSuggestion.email,
              fullName: emailSuggestion.fullName,
            }));
            setCcSuggestions(emailSuggestions);
          });
        } else {
          setCcSuggestions([]);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (emailInput.length > 0) {
          graphQLClient.request(GET_EMAIL_SUGGESTIONS, { term: emailInput }).then((res) => {
            const emailSuggestions = res?.userEmailsByTerm?.map((emailSuggestion: EmailSuggestion) => ({
              id: emailSuggestion.email,
              description: emailSuggestion.email,
              fullName: emailSuggestion.fullName,
            }));
            setSuggestions(emailSuggestions);
          });
        } else {
          setSuggestions([]);
        }
      }
    },
    [graphQLClient],
  );
  const onAddEmailClick = () => {
    // @ts-ignore
    const email = ccEmailInputRef.current.value;
    const testEmail = emailRegexNoGlobal.test(email !== undefined ? email : '');

    setInvalidCcEmailAlert(!testEmail);

    if (!testEmail) {
      return;
    }

    if (email && testEmail) {
      setCcRecipients([...ccRecipients, { email, id: tempValue, selected: true }]);

      setSuggestions([]);
      setCcSuggestions([]);
      if (emailInputRef?.current) {
        // @ts-ignore
        emailInputRef.current.value = '';
      }
      if (ccEmailInputRef?.current) {
        // @ts-ignore
        ccEmailInputRef.current.value = '';
      }
    }
  };
  const onOptionSelect = (option?: any, isCc?: boolean) => {
    if (isCc) {
      if (option) {
        setCcRecipients([
          ...ccRecipients,
          { email: option.description, id: tempValue, selected: true, fullName: option.fullName },
        ]);
      } else {
        // @ts-ignore
        setCcRecipients([
          ...ccRecipients,
          // @ts-ignore
          { email: ccEmailInputRef.current.value, id: tempValue, selected: true, fullName: tempValue },
        ]);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (option) {
        setSelectedEmails([{ email: option.description, id: tempValue, selected: true, fullName: option.fullName }]);
      } else {
        // @ts-ignore
        setSelectedEmails([
          // @ts-ignore
          { email: emailInputRef.current.value, id: tempValue, selected: true, fullName: option.fullName },
        ]);
      }
    }
    setSuggestions([]);
    setCcSuggestions([]);
    if (emailInputRef?.current) {
      // @ts-ignore
      emailInputRef.current.value = '';
    }
    if (ccEmailInputRef?.current) {
      // @ts-ignore
      ccEmailInputRef.current.value = '';
    }
  };

  const onCustomContactChange = useCallback(
    (event: any, contact: CustomEmailContact) => {
      if (event.detail.checked) {
        const filteredSelectedEmails = selectedEmails.filter((selected) => selected.email !== contact.email);
        const newSelectedEmails = [...filteredSelectedEmails, { ...contact, selected: true }];

        setSelectedEmails(newSelectedEmails);
      } else if (contact.id === tempValue) {
        const filteredSelectedEmails = selectedEmails.filter((selected) => selected.email !== contact.email);
        const newSelectedEmails = [...filteredSelectedEmails, { ...contact, selected: false }];

        setSelectedEmails(newSelectedEmails);
      } else {
        const updatedSelectedEmails = selectedEmails.filter((selected) => selected.email !== contact.email);

        setSelectedEmails(updatedSelectedEmails);
      }
    },
    [selectedEmails],
  );

  // TODO: ADD "DO YOU WANT TO DELETE" MODAL
  const removeCcRecipient = (email: string) => {
    setCcRecipients(ccRecipients.filter((recipient) => recipient.email !== email));
  };

  useEffect(() => {
    register('recipients', { value: selectedEmails?.map((r) => r.email) });
    register('ccRecipients', { value: ccRecipients?.map((r) => r.email) });
  }, [selectedEmails, ccRecipients, register]);

  const isEdit = notification && Object.keys(notification).length > 0;
  const enableSave = !isEdit && (type === MessageChannel.SMS ? !!phone : !!claim?.projEmail);

  let templateOptions: Option[];
  if (isEdit) {
    templateOptions = [{ label: t('arrivalConfirmation'), value: MessageTemplate.ArrivalConfirmation },
      { label: t('onTheWay'), value: MessageTemplate.OnTheWay },
      { label: t('freeText'), value: MessageTemplate.FreeText },
      { label: t('onTheWayCrew'), value: MessageTemplate.OnTheWayCrew },
    ];

  } else if (isCrewNotificationsEnabled) {
    templateOptions = [{ label: t('onTheWay'), value: MessageTemplate.OnTheWayCrew }];
  } else {
    templateOptions = [
      { label: t('arrivalConfirmation'), value: MessageTemplate.ArrivalConfirmation },
      { label: t('onTheWay'), value: MessageTemplate.OnTheWay },
      { label: t('freeText'), value: MessageTemplate.FreeText },
    ];    
  }
  

  useEffect(() => {
    if (isEdit) {
      const messageTemplate = notification?.metadata?.find(
        (n: { key: string; value: string }) => n.key === 'messageTemplate',
      );
      if (isCrewNotificationsEnabled) {
        setValue('template', MessageTemplate.OnTheWayCrew);
      } else { 
        // eslint-disable-next-line no-lonely-if
        if (messageTemplate?.value === MessageTemplate.OnTheWay) {
          setValue('template', MessageTemplate.OnTheWay);
        } else if (messageTemplate?.value === MessageTemplate.FreeText) {
          setValue('template', MessageTemplate.FreeText);
        } else if (messageTemplate?.value === MessageTemplate.OnTheWayCrew) {
          setValue('template', MessageTemplate.OnTheWayCrew);
        } else {
          setValue('template', MessageTemplate.ArrivalConfirmation);
        }
      }
      if (notification?.sentToXa) {
        setIsSendToXact(true);
      }
      const eta = notification?.metadata?.find((n: { key: string; value: string }) => n.key === 'eta');
      const notificationTemplate = notification?.metadata?.find((n: { key: string; value: string }) => n.key === 'messageTemplate');

      if (eta?.value && notificationTemplate?.value !== MessageTemplate.FreeText) {
        if (
          messageTemplate?.value === MessageTemplate.OnTheWay ||
          messageTemplate?.value === MessageTemplate.OnTheWayCrew
        ) {
          setValue('when', eta?.value);
          setWhenDate(eta?.value);
        } else {
          setValue('when', toIsoLocalTime(eta?.value));
          setWhenDate(toIsoLocalTime(new Date(eta?.value)));
        }
      }
      setValue('message', notification.body);
      const type = notification?.messageType === 'MailMessage' ? MessageChannel.Email : MessageChannel.SMS;
      setValue('type', type);
      if (type === MessageChannel.SMS) {
        setValue('phone', notification.recipients[0]);
      }

      const who = notification?.metadata?.find((n: { key: string; value: string }) => n.key === 'userName');
      if (who?.value) {
        setSelectedEmails([{ email: who.value, id: tempValue, selected: true }]);
      }
      const workType = notification?.metadata?.find((n: { key: string; value: string }) => n.key === 'workType');
      setValue('workType', workType?.value);
    }
  }, [isEdit, notification, template, setValue, isCrewNotificationsEnabled]);

  return (
    <>
      <IonContent className="ion-padding">
        <form id="addNotesForm" onSubmit={onSubmitHandler}>
          <FosSelectItem
            disabled={isEdit}
            label={t('type')}
            options={typeOptions}
            {...register('type')}
            error={errors.type?.message}
          />
          {type === MessageChannel.SMS && (
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <IonItem
                  lines="none"
                  className={`fos-stacked-input-item ion-margin-bottom ${error ? 'ion-invalid' : ''}`.trim()}
                >
                  <IonInput
                    type="tel"
                    readonly={isEdit}
                    ref={async (phoneInput) => {
                      if (phoneInput) {
                        const input = await phoneInput.getInputElement();
                        phoneMask(input);
                      }
                    }}
                    className="fos-stacked-input"
                    placeholder={defaultPhone}
                    defaultValue={defaultPhone}
                    value={value}
                    onChange={onChange}
                    onIonChange={onChange}
                  />
                  {error?.message && <IonNote slot="error">{error.message}</IonNote>}
                </IonItem>
              )}
            />
          )}
          <FosSelectItem
            label={t('Template')}
            disabled={isEdit}
            options={templateOptions}
            {...register('template')}
            error={errors.template?.message}
          />
          {!isEdit && (
            <AutocompleteSearch
              disabled={isEdit}
              ref={emailInputRef}
              placeholder={`${t('whoFosStaff').toString()} *`}
              onChange={(evt: any) => {
                getEmailSuggestions(evt.target.value, false);
                if (errors.who) {
                  errors.who.message = '';
                }
              }}
              options={suggestions}
              onOptionSelect={(option) => onOptionSelect(option)}
              debounce={1000}
              error={errors.who?.message}
              marginBottom
            />
          )}
          {selectedEmails.length > 0 && (
            <div className="selected-section recents-section recents-label">
              <IonLabel>{t('selectedEmployee').toString()}</IonLabel>
              <FormItemDivider />
              <IonList>
                {selectedEmails.map((contact, index) => (
                  <div key={`${contact.email + index}`}>
                    <IonItem lines="none" className="ion-no-padding">
                      <IonCheckbox
                        disabled
                        className="form-item-checkbox"
                        slot="start"
                        color="primary"
                        checked={contact.selected!}
                        onIonChange={(e) => onCustomContactChange(e, contact)}
                      />
                      <IonLabel>
                        <IonText className="recents-label">{contact.fullName}</IonText> -{' '}
                        <IonText className="fos-share-with-contact-value">{contact.email}</IonText>
                      </IonLabel>
                    </IonItem>
                    <FormItemDivider />
                  </div>
                ))}
              </IonList>
            </div>
          )}
          {(isArrivalConfirmationSelected) && (
            <IonItem
              disabled={isEdit}
              id="date-time-trigger"
              lines="none"
              className="fos-select-date-item ion-margin-top"
            >
              <div className="fos-select-date-border" />
              <IonLabel id="open-date-input" className="ion-text-wrap">
                <IonText className="fos-select-date-label">{t('when').toString()} *</IonText>
              </IonLabel>
              <IonText className="fos-select-date-label">
                {moment(whenDate).isValid() ? moment(whenDate).format('dddd, MMMM Do YYYY, h:mm a') : ''}
              </IonText>
              <IonModal trigger="date-time-trigger" className="center fixed-300-width">
                <IonDatetime
                  showDefaultButtons
                  presentation="date-time"
                  id="idWhenDate"
                  name="idWhenDate"
                  size="cover"
                  value={whenDate}
                  onIonChange={(v) => {
                    setWhenDate(v.target.value);
                    if (errors.when) {
                      errors.when.message = '';
                    }
                  }}
                  max={maxDate}
                />
              </IonModal>
            </IonItem>
          )}
          {(isOnTheWaySelected || isOnTheWayCrewSelected) && (
            <FosSelectItem
              disabled={isEdit}
              removeMarginBottom
              label={`${t('when')} *`}
              marginTop
              onIonChange={() => {
                if (errors.when) {
                  errors.when.message = '';
                }
              }}
              options={whenOptions}
              {...register('when')}
              interfaceOptions={{ header: t('when') }}
            />
          )}          
          {errors.when?.message && (
            <IonNote className="note-error" slot="end">
              {errors.when?.message}
            </IonNote>
          )}
          {isOnTheWayCrewSelected && (
            <FosSelectItem
              disabled={isEdit}
              removeMarginBottom
              label={`${t('work')} *`}
              marginTop
              onIonChange={() => {
                if (errors.workType) {
                  errors.workType.message = '';
                }
              }}
              options={workTypeOptions}
              {...register('workType')}
              interfaceOptions={{ header: t('work') }}
            />
          )}
          {errors.workType?.message && (
            <IonNote className="note-error" slot="end">
              {errors.workType?.message}
            </IonNote>
          )}
          {isFreeTextSelected && <div />}
          {(!isOnTheWayCrewSelected || isEdit) && (
            <>
              <div className="ion-margin-top" />
              <FosFormatTextArea
                label={t('details').toString()}
                hideToolBar={type === MessageChannel.SMS || isEdit}
                control={control}
                readonly={isEdit}
                name="message"
              />
            </>
          )}
          <IonToast
            isOpen={!!saveError}
            duration={3000}
            message={saveError}
            buttons={[{ role: 'cancel', icon: closeOutline }]}
            onDidDismiss={() => {
              setSaveError('');
            }}
          />
          {(isLinkedToXact && !isCrewNotificationsEnabled && template !== MessageTemplate.OnTheWay) && (
            <IonRow className="ion-no-padding ion-no-margin">
                <IonCol>
                  <IonItem lines="none">
                    <IonCheckbox
                      disabled={isEdit}
                      checked={!!isSendToXact}
                      value={!!isSendToXact}
                      onIonChange={() => setIsSendToXact(!isSendToXact)}
                    />
                    <IonLabel>{t('submitToXact').toString()}</IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
          )}
          {type === MessageChannel.Email && (
            <div className="sent-emails">
              {(claim?.projEmail || isEdit) && (
                <>
                  <IonLabel>
                    <IonText className="fos-share-with-contact-title">{t('messageTo').toString()}:</IonText>
                  </IonLabel>
                  <IonList>
                    <IonItem lines="none" className="ion-no-padding">
                      <IonLabel>
                        {notification?.recipients && notification.recipients[0] ? (
                          <IonText className="fos-share-with-contact-value">{notification.recipients[0]}</IonText>
                        ) : (
                          <div>
                            <IonText>{claim?.contactName}</IonText>
                            {claim?.contactName && ' - '}
                            <IonText className="fos-share-with-contact-value">{claim?.projEmail}</IonText>
                          </div>
                        )}
                      </IonLabel>
                    </IonItem>
                  </IonList>
                </>
              )}
              {type === MessageChannel.Email && (
                <>
                  {!isEdit && (
                    <AutocompleteSearch
                      disabled={isEdit}
                      ref={ccEmailInputRef}
                      placeholder={`${t('CcEmails').toString()}`}
                      onChange={(evt: any) => {
                        getEmailSuggestions(evt.target.value, true);
                      }}
                      options={ccSuggestions}
                      onOptionSelect={(option) => onOptionSelect(option, true)}
                      debounce={1000}
                      addButton
                      onAddClick={() => onAddEmailClick()}
                      disableEnter
                    />
                  )}
                  {isInvalidCcEmailAlertOpen && (
                    <IonText className="invalid-entry-message">{t('pleaseEnterAValidEmailAddress').toString()}</IonText>
                  )}
                  {ccRecipients.length > 0 && (
                    <div className="selected-section recents-section recents-label">
                      <IonLabel>{t('selectedCcEmails').toString()}</IonLabel>
                      <FormItemDivider />
                      <IonList>
                        {ccRecipients.map((contact, index) => (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                          <div key={`${contact.email + index}`} onClick={() => removeCcRecipient(contact.email)}>
                            <IonItem lines="none" className="ion-no-padding">
                              <IonCheckbox
                                disabled
                                className="form-item-checkbox"
                                slot="start"
                                color="primary"
                                checked={contact.selected!}
                                onIonChange={(e) => onCustomContactChange(e, contact)}
                              />
                              <IonLabel>
                                <IonText className="fos-share-with-contact-value">{contact.email}</IonText>
                              </IonLabel>
                            </IonItem>
                            <FormItemDivider />
                          </div>
                        ))}
                      </IonList>
                    </div>
                  )}
                </>
              )}
              {!claim?.projEmail && !isEdit && (
                <IonItem lines="none">
                  <IonLabel>{t('missingContactInfoAtClaimDetails').toString()}</IonLabel>
                </IonItem>
              )}
            </div>
          )}
        </form>
        <IonLoading isOpen={isFetching} message={t('loading')} duration={2000} />



        <IonModal id="send-to-xa-modal" ref={generateModal} isOpen={showSendToXaModal} onDidDismiss={() => setShowSendToXaModal(false)}>
        <IonHeader>
          <h1 className="h1-center">{t('submitToXact').toString()}</h1>
        </IonHeader>
        <IonContent className="wrapper" id="phase-content">
          <IonItem className="ion-item-border" lines="none">
            <IonLabel>{t('phase').toString()}</IonLabel>
            <IonSelect
              value={phaseIndxWithXA}
              onIonChange={(event) => setPhaseIndxWithXA(event.detail.value)}
            >
              {claim?.phases?.filter((phase: { linkedToXA: any; }) => phase.linkedToXA).map((phase: { phaseIndx: React.Key | null | undefined; phaseCode: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<ReactI18NextChild> | null | undefined; }) => (
                <IonSelectOption key={phase.phaseIndx} value={phase.phaseIndx}>
                  {phase.phaseCode}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonContent>
        <IonFooter className="phase-footer">
          <IonButton className="generate-btn" onClick={onSubmitHandler}>
            {t('submit').toString()}
          </IonButton>
        </IonFooter>
      </IonModal>


      
      </IonContent>
      <IonFooter className="ion-padding claim-notes-form-footer">
        <IonButton disabled={!enableSave} expand="block" fill="solid" onClick={async () => {
          if (isSendToXact) {
            const output = await trigger();
            if (output) {
              setShowSendToXaModal(true);
            }
          } else {onSubmitHandler();}
        }}>
          {t('send').toString()}
        </IonButton>
      </IonFooter>
      <IonLoading isOpen={isLoading} message={t('saving')} duration={20000} />
    </>
  );
};

export default ClaimNotificationForm;
