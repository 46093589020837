import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonRouter,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { close } from 'ionicons/icons';
import { t } from 'i18next';
import LoginPage from './auth/Login';
import TabsPage from './Tabs';
import ClaimDetails from './ClaimDetails';
import AddJob from './AddJob';
import Inspection from './Inspection';
import './Routes.css';
import ScanEquipment from '../features/equipment/view/scan/ScanEquipment';
import ClaimEquipmentAssignment from '../features/equipment/view/assign/ClaimEquipmentAssignment';
import EquipmentEdition from '../features/equipment/view/edition/EquipmentEdition';
import EquipmentsByClasses from '../features/equipment/view/list/equipmentsclass/EquipmentsByClasses';
import EquipmentDetails from '../features/equipment/view/details/EquipmentDetails';
import ScanBatchOptions from '../features/equipment/view/scan/ScanBatchOptions';
import AddExpense from './AddExpense';
import AddMileageExpense from './AddMileageExpense';
import { useAuth } from '../hooks/authContext';
import { HYBRID } from '../hooks/photo';
import useGetAppVersion from '../hooks/useGetAppVersion';
import openDocumentURL from './helper/Documents';
import { APP_VERSION } from './helper/Const';
import { checkVersion } from './helper/AppHelper';

type Props = {};
const CHECK_LAST_DAY = 'CHECK_LAST_DAY';

const RoutesChild = () => {
  const { userState, checkTokenExpiration } = useAuth()!;
  const [isVersionUpdateOpen, setIsVersionUpdateOpen] = useState(false);
  const navigation = useIonRouter();
  const useAppVersion = useGetAppVersion(userState, isPlatform(HYBRID));

  useEffect(() => {
    const currentPath = navigation.routeInfo.pathname;
    if (userState.initialized && userState.userAuthPayload?.accessToken === undefined && navigation && currentPath !== '/login') {
      navigation.push('/login');
    }
  }, [navigation, userState.initialized, userState.userAuthPayload?.accessToken]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userState.userAuthPayload?.accessToken && !isPlatform(HYBRID)) {
        checkTokenExpiration();
      }
    }, 15 * 1000); // each 15 seconds check token expiration for web
    return () => {
      clearInterval(intervalId);
    };
  }, [checkTokenExpiration, userState.userAuthPayload?.accessToken]);

  const getDateDifference = (date1: Date, date2: Date) => {
    const diffInMs = Math.abs(date2.getTime() - date1.getTime());
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays > 1;
  };

  useEffect(() => {
    const getVersion = async () => {
      if (!checkVersion(APP_VERSION, useAppVersion.data?.appVersion?.minVersion)) {
        const lastCheck = localStorage.getItem(CHECK_LAST_DAY);
        if (!lastCheck || getDateDifference(new Date(), new Date(lastCheck))) {
          localStorage.setItem(CHECK_LAST_DAY, new Date().toString());
          setIsVersionUpdateOpen(true);
        }
      }
    };
    
    // user should be logged and only work for app
    const currentPath = navigation?.routeInfo?.pathname || '';
    if (userState.loggedIn && 
      userState.userAuthPayload?.accessToken && 
      isPlatform(HYBRID) && 
      currentPath && (currentPath === '/tabs/profile' || currentPath === '/tabs/jobs') && 
      !useAppVersion.isLoading && useAppVersion.data?.appVersion?.minVersion
    ) {  
      getVersion();
    }
  }, [navigation, useAppVersion, userState]);

  return (
  <>
    <Route exact path="/login" component={LoginPage} />
    <Route exact path="/new-job" component={AddJob} />
    <Route exact path="/edit-job/:jobIdx/:isOffline?" component={AddJob} />
    <Route exact path="/jobs/:jobIdx/:isOffline?" component={ClaimDetails} />
    <Route exact path="/jobs/:jobIdx/site-inspection/:claimNumber/:lossType/:yearOfConstruction/:insurer" component={Inspection} />
    <Route exact path="/equipments-classes/:classIdParam" component={EquipmentsByClasses} />
    <Route exact path="/equipment-edition/:itemId/:assetTag?" component={EquipmentEdition} />
    <Route exact path="/equipment-assignment/:equipmentsIds?" component={ClaimEquipmentAssignment} />
    <Route exact path="/scan-equipment/:scanType/:action?" component={ScanEquipment} />
    <Route exact path="/equipment-details/:regionId/:itemId" component={EquipmentDetails} />
    <Route exact path="/scan-batch-options" component={ScanBatchOptions} />
    <Route exact path="/expense/:expenseId?" component={AddExpense} />
    <Route exact path="/credit-card-expense/:expenseId?" component={AddExpense} />
    <Route exact path="/mileage-expense/:expenseId?" component={AddMileageExpense} />
    <Route path="/tabs" component={TabsPage} />

    <Route exact path="/">
      <Redirect to="/login" />
    </Route>
    <IonModal isOpen={isVersionUpdateOpen} onDidDismiss={() => setIsVersionUpdateOpen(false)} >
      <IonHeader>
        <IonToolbar class="phase-selector-header">
          <IonTitle>{t('newVersionAvailable').toString()}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setIsVersionUpdateOpen(false)}>
              <div className="close-icon-container">
                <IonIcon className="tools-close-button" icon={close} />
              </div>{' '}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding item-background-color" scrollY={false}>
        <IonCard>
          <IonCardContent>
            <IonLabel>{t('outDateText').toString()}</IonLabel><br /><br />
            <IonLabel>{t('linkBelowText').toString()}</IonLabel>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <IonFooter className='levels-done-footer ion-padding'>
        <IonButton
          expand='block'
          fill='solid'
          onClick={() => openDocumentURL(useAppVersion.data?.appVersion?.url)}
        >
          {t('installUpdateBtn').toString()}
        </IonButton>
      </IonFooter>
    </IonModal>
  </>
  );
};

const Routes: React.FC<Props> = () => (
  <div className="app-container">
    <IonReactRouter>
      <IonRouterOutlet>
        <RoutesChild />
      </IonRouterOutlet>
    </IonReactRouter>
  </div>
);

export default Routes;