import { gql } from '@apollo/client';

export type Metadata = {
  key: string,
  value: string,
};

export type ClaimNotificationsType = {
  clientMessageKey: string,
  recipients?: string[],
  subject: string,
  body: string;
  messageType: string,
  sender: string,
  submittedDateTime?: string,
  messageDirection: string,
  metadata: Metadata[],
  ccAddresses?: string[],
  sentToXa?: boolean,
  id: string
};

const GET_CLAIM_NOTIFICATIONS = gql`
query communicationMessages ($claimNumber: String) {
  communicationMessages(claimNumber: $claimNumber) {
    clientMessageKey
    recipients
    ccAddresses
    body
    sentToXa
    messageType
    submittedDateTime
    messageDirection
    sender
    clientId
    id
    metadata {
      key
      value
    }
  }
}`;

export default GET_CLAIM_NOTIFICATIONS;