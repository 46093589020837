import React, { FC, useState, useEffect } from 'react';
import { IonContent, IonHeader, IonPage, IonToolbar, useIonRouter } from '@ionic/react';
import UserProfile from '../molecule/UserProfile';
import { useAuth } from '../hooks/authContext';
import Menu, { ActiveOption } from '../molecule/Menu';
import Badge from '../molecule/Badge';
import UserSettings from '../molecule/UserSettings';

import './Profile.css';
import { ProfileActiveOption } from './helper/Const';
import UserExpenseMenu from '../molecule/UserExpenseMenu';

const getActivetab = () => {
  const activeTab = localStorage.getItem('activeProfileTab');

  if (activeTab) {
    const tabEnumKey = activeTab as keyof typeof ProfileActiveOption;
    return ProfileActiveOption[tabEnumKey];
  }

  return ProfileActiveOption.Details;
};

type Props = {};

const Profile: FC<Props> = () => {
  const { user, logOut } = useAuth()!;
  const [activeOption, setActiveOption] = useState<ActiveOption>(getActivetab());
  const navigation = useIonRouter();

  useEffect(() => {    
    if (activeOption !== 'Expenses' && localStorage.getItem('activeProfileTab') === 'Expenses') {
      localStorage.removeItem('userExpensesCategorySelected');
      localStorage.removeItem('userExpensesForApprovalCategorySelected');
      localStorage.removeItem('userExpensesForApprovalSearchTerm');
      localStorage.removeItem('userExpensesSearchTerm');
      localStorage.removeItem('userExpensesForApprovalStatusId');
      localStorage.removeItem('userExpensesStatusId');
      localStorage.removeItem('selectedExpenseMenu');
    }
    localStorage.setItem('activeProfileTab', activeOption);
  }, [activeOption]);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="ion-no-padding profile-header">
          <Menu activeOption={activeOption} onOptionPress={setActiveOption} />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {activeOption === ProfileActiveOption.Details && <UserProfile user={user} />}
        {activeOption === ProfileActiveOption.Badge && <Badge user={user} />}
        {activeOption === ProfileActiveOption.Settings && <UserSettings logout={async () => {
          await logOut();
          navigation.push('/login', 'root', 'replace');
        }} />}
        {activeOption === ProfileActiveOption.Expenses && <UserExpenseMenu />}
      </IonContent>
    </IonPage>
  );
};

export default Profile;
