import React from 'react';
import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonNote,
  IonRow,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import FosTextArea from '../atom/FosTextArea';
import useBranches from '../hooks/branches';
import { useAuth } from '../hooks/authContext';
import useStorage from '../hooks/storage';
import useExpenseCategoriesByBranch from '../hooks/expCategoriesByBranch';
import useExpenseDepartments from '../hooks/expDepartments';
import ExpenseTaxes, { ExpTax } from './ExpTaxes';
import FosCurrencyInput from '../components/FosCurrencyInput';
import FosSelectBigListItem from '../atom/FosSelectBigListItem';
import { ExpenseDepartmentData } from '../pages/helper/ExpenseDepartmentType';

export type SimpleExpenseFormData = {
  id: number
  creditCardId?: string
  creditCardNumber: string
  branch: number
  branchName: string
  category: number
  department: number
  totalAmount: string
  taxes: ExpTax[]
  comments: string,
  hasTaxes: boolean
};

export type SimpleExpenseFormErrors = {
  id?: string
  creditCardId?: string,
  creditCardNumber?: string,
  branch?: string
  branchName?: string
  category?: string
  department?: string
  totalAmount?: string
  taxes?: string
  comments?: string
  hasTaxes?: string
  employeeName?: string
};

export interface Props {
  id: string
  errors?: SimpleExpenseFormErrors
  showTotalAmount?: boolean;
  location: string;
  expense: SimpleExpenseFormData;
  editMode: boolean;
  onChange: (id: keyof SimpleExpenseFormData, value: any, data: SimpleExpenseFormData) => any
}

export function createSimpleExp(initialValues?: Partial<SimpleExpenseFormData>): SimpleExpenseFormData {
  const tempId = Math.trunc(Math.random() * (10000000 - 1000000) + 1000000);
  return {
    id: tempId,
    branch: initialValues?.branch || 0,
    branchName: initialValues?.branchName || '',
    category: initialValues?.category || 0,
    department: initialValues?.department || 0,
    totalAmount: initialValues?.totalAmount || '',
    taxes: initialValues?.taxes || [],
    comments: initialValues?.comments || '',
    hasTaxes: false,
    creditCardNumber: '',
    creditCardId: undefined,
  };
}

export function getSimpleExpErrors(expense: SimpleExpenseFormData, t: TFunction): SimpleExpenseFormErrors {
  const errs: SimpleExpenseFormErrors = {};
  if (!expense.branch) errs.branch = t('branchIdIsRequired');
  if (!expense.category) errs.category = t('categoryIsRequired');
  if (!expense.department) errs.department = t('departmentIsRequired');
  if (!expense.comments) errs.comments = t('commentsFieldIsRequired');
  if (!expense.totalAmount) errs.totalAmount = t('expenseTotalAmountIsRequired');
  if (expense.hasTaxes && expense.taxes.length === 0 || expense.taxes.some(x => !x.total)) errs.taxes = t('taxIsRequired');

  return errs;
}

export const SimpleExpenseForm: React.FC<Props> = ({ id, showTotalAmount, location, expense, errors, editMode, onChange }) => {
  const { userState } = useAuth()!;
  const { t } = useTranslation();
  const regionId = userState.userAuthPayload?.regionId!;
  const storage = useStorage();
  const branches = useBranches(regionId, userState, storage);
  const { data: expenseCategoriesByBranch } = useExpenseCategoriesByBranch(expense.branch);
  const { data: expenseDepartments } = useExpenseDepartments(expense.branch, expense.category);
  const branchesOptions = branches.sort((a, b) => a.branchName.localeCompare(b.branchName)).map(branch => ({ label: branch.branchName, value: branch.branchId.toString() }));
  const categoryOptions = expenseCategoriesByBranch.sort((a, b) => a.category.name.localeCompare(b.category.name)).map(expCat => ({ label: expCat.category.name, value: expCat.category.id.toString() }));
  const expenseDepartmentsOptions = ((expenseDepartments?.expenseDepartments as ExpenseDepartmentData)?.items || []).sort((a, b) => a.department.localeCompare(b.department)).map(expDep => ({ label: `${expDep.department}`, value: expDep.departmentId.toString() }));
  if (!errors) errors = {};

  const handleTaxValueChange = (taxes: ExpTax[]) => {
    const exp: SimpleExpenseFormData = { ...expense, taxes };
    onChange('taxes', taxes, exp);
  };

  const handleExpenseRequired = (isRequired: boolean) => {
    expense.hasTaxes = isRequired;
  };

  const handleValueChange = (id: keyof SimpleExpenseFormData, value: any) => {
    const exp: SimpleExpenseFormData = { ...expense, ...{ [id]: value } };
    if (id === 'branch') {
      exp.branchName = branchesOptions.find(b => b.value === value)?.label || '';
    }
    onChange(id, value, exp);
  };

  return (
    <form className="fos-form ion-margin-top">
      <IonGrid className="ion-no-padding ion-no-margin">
        <IonRow>
          <IonCol>
            <FosSelectBigListItem
              required
              disabled={!editMode}
              id={`${id}-branch-simple-expense-form`}
              name="branch-simple-expense-form"
              value={`${expense.branch}`}
              onChange={(val: string) => { handleValueChange('branch', val); }}
              label={t('branch')}
              error={errors.branch}
              options={branchesOptions}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FosSelectBigListItem
              required
              id={`${id}-category-simple-expense-form`}
              name="category-simple-expense-form"
              value={`${expense.category}`}
              onChange={(val: string) => { handleValueChange('category', val); }}
              label={t('category')}
              disabled={!expense.branch || !editMode}
              options={categoryOptions}
              error={errors.category}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FosSelectBigListItem
              required
              id={`${id}-department-simple-expense-form`}
              name="department-simple-expense-form"
              value={`${expense.department}`}
              onChange={(val: string) => { handleValueChange('department', val); }}
              label={t('department')}
              disabled={!expense.branch || !expense.category || !editMode}
              options={expenseDepartmentsOptions}
              error={errors.department}
            />
          </IonCol>
        </IonRow>
        {showTotalAmount && <IonRow className="ion-margin-top">
          <IonCol>
            <IonItem lines="none">
              <IonLabel>{`${t('totalAmount').toString()} *`}</IonLabel>
              <FosCurrencyInput disabled={!editMode} value={expense.totalAmount} onChange={(val) => handleValueChange('totalAmount', val)} />
            </IonItem>
            {errors.totalAmount && <p className="ion-padding-start ion-no-margin ion-padding-bottom">
              <small>
                <IonNote color="danger">{errors.totalAmount}</IonNote>
              </small>
            </p>}
          </IonCol>
        </IonRow>}
        <IonRow>
          <IonCol>
            <ExpenseTaxes editMode={editMode} error={errors.taxes} onChange={handleTaxValueChange} isRequired={handleExpenseRequired} taxes={expense.taxes} location={location} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FosTextArea disabled={!editMode} error={errors.comments} required value={expense.comments} onIonChange={(event) => handleValueChange('comments', event.detail.value)} placeholder={t('comments')} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  );
};

export default SimpleExpenseForm;
