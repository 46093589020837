/* eslint-disable no-console */
import { yupResolver } from '@hookform/resolvers/yup';
import DOMPurify from 'dompurify';
import { t } from 'i18next';
import * as yup from 'yup';
import { useState, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useAuth } from '../hooks/authContext';
import useLossTypes from '../hooks/lossTypes';
import useSiteInspectionInfo from '../hooks/siteInspectionInfo';
import useStorage from '../hooks/storage';
import useTemplates from '../hooks/templates';
import { ApiStatus, ClaimOnlineStatus } from './helper/Const';
import { numberRegex } from './helper/FormsHelper';
import { classTypeList, categoryOptionsList } from './helper/SiteInspectionInfoHelper';
import NetworkContext from '../contexts/NetworkContext';
import { getClaimSiteInfo, storeClaimSiteInfo, useSaveSiteInfoMutation } from './helper/offlineHelper/ClaimSiteInfoHelper';
import { useClaimOffline } from '../contexts/ClaimOfflineContext';
import { getErrorMsg } from './helper/AppHelper';

type Props = {
  claimIndx: string;
  isMandatoryFieldsInvalid: boolean;
  setIsLossTypeValid: (isValid: boolean) => void;
  mandatoryFieldsInvalid: (isValid: boolean) => void;
  hasChangedMessageControl: (isChanged: boolean) => void;
};

const DETAILED_LOSS_TYPE = ['Sewer Backup', 'Water', 'Toilet leak/crack'];

interface RouteParams {
  insurer: string;
}
type FormData = {
  claimId: string;
  id: number;
  causeOfLoss: string;
  class: string;
  categoryOfLoss: string;
  categoryOfSource: string;
  hvacaffected: boolean;
  suddenLoss: boolean;
  nonRestorableContent: boolean;
  emAfterHours: boolean;
  emEstimateNeeded: boolean;
  repairEstimateNeeded: boolean;
  tearOutNeeded: boolean;
  lossOfIncome: boolean;
  mobilizationEta?: string;
  emReserve: number;
  reReserve: number;
  ctReserve: number;
  generalCauseDetails: string;
  preExistingFactors: string;
  fieldCrewNotes: string;
  areaDamages: string;
  contents: string;
  docusketchLink: string;
  dryingStrategy?: string;
  emEstimatedDuration?: string;
  reEstimatedDuration?: string;
  contentsHandling?: string;
  otherInfoBox?: string;
  isAsbestos?: boolean;
  isMold?: boolean;
  preexistingDamage?: boolean;
  isAviva?: boolean;
};

const SiteInspectionInfoViewModel = ({ claimIndx, isMandatoryFieldsInvalid, mandatoryFieldsInvalid, setIsLossTypeValid }: Props) => {
  const { userState } = useAuth()!;
  const region = userState.userAuthPayload?.regionId!;
  const storage = useStorage();
  const lossTypes = useLossTypes(region, userState, storage);
  const lossTypeOptions = lossTypes.map((lossType) => ({ label: lossType.lossCategory, value: lossType.lossCategory }));
  const classTypeOptions = classTypeList.map((classType) => ({ label: classType.value, value: classType.value }));
  const lossCategoryOptions = categoryOptionsList.map((category) => ({ label: category.label, value: category.value }));
  const sourceCategoryOptions = categoryOptionsList.map((category) => ({
    label: category.label,
    value: category.value,
  }));
  const [saveError, setSaveError] = useState('');
  const [siteInspectionInfo, setSiteInspectionInfo] = useState<any>(null);
  const network = useContext(NetworkContext);
  const { refresh } = useClaimOffline()!;
  const { insurer } = useParams<RouteParams>();
  const [isCauseOfLossValid, setIsCauseOfLossValid] = useState(false);
  const [isClassValid, setIsClassValid] = useState(false);
  const [isLossCategoryValid, setIsLossCategoryValid] = useState(false);
  const [isSourceCategoryValid, setIsSourceCategoryValid] = useState(false);
  const [isDryingStrategyValid, setIsDryingStrategyValid] = useState(false);
  const [initialValuesSet, setInitialValuesSet] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  enum MandatoryFields {
    CauseOfLoss = 'causeOfLoss',
    Class = 'class',
    LossCategory = 'categoryOfLoss',
    SourceCategory = 'categoryOfSource',
    DryingStrategy = 'dryingStrategy',
  }

  const schema = yup
    .object({
      causeOfLoss: yup.string().required(t('lossTypeIsRequired')),
      class: yup.string().when('causeOfLoss', {
        is: (lossType: string) => DETAILED_LOSS_TYPE.includes(lossType),
        then: yup.string().required(t('classTypeIsRequired')),
      }),
      categoryOfLoss: yup.string().when('causeOfLoss', {
        is: (lossType: string) => DETAILED_LOSS_TYPE.includes(lossType),
        then: yup.string().required(t('lossCategoryIsRequired')),
      }),
      categoryOfSource: yup.string().when('causeOfLoss', {
        is: (lossType: string) => DETAILED_LOSS_TYPE.includes(lossType),
        then: yup.string().required(t('sourceCategoryIsRequired')),
      }),
      dryingStrategy: yup.string().when(['causeOfLoss', 'isAviva'], {
        is: (causeOfLoss: string, isAviva: any) => causeOfLoss === 'Water' && isAviva,
        then: yup
          .string()
          .required(t('dryingStrategyIsRequired'))
          .test({
            name: 'notEmpty',
            message: t('dryingStrategyIsRequired'),
            test: (value) => value?.trim() !== '',
          })
          .test({
            name: 'notBlankHtml',
            message: t('dryingStrategyIsRequired'),
            test: (value) => value !== '<p><br></p>',
          }),
      }),
      hvacaffected: yup.bool(),
      emReserve: yup.string().when({
        is: (val: string) => val?.length > 0,
        then: yup.string().matches(numberRegex, { message: t('currencyField') }),
      }),
      reReserve: yup.string().when({
        is: (val: string) => val?.length > 0,
        then: yup.string().matches(numberRegex, { message: t('currencyField') }),
      }),
      ctReserve: yup.string().when({
        is: (val: string) => val?.length > 0,
        then: yup.string().matches(numberRegex, { message: t('currencyField') }),
      }),
      generalCauseDetails: yup.string(),
      preExistingFactors: yup.string(),
      fieldCrewNotes: yup.string(),
      areaDamages: yup.string(),
      contents: yup.string(),
      docusketchLink: yup.string(),
      emEstimatedDuration: yup.string(),
      reEstimatedDuration: yup.string(),
      contentsHandling: yup.string(),
      otherInfoBox: yup.string(),
      isAsbestos: yup.bool(),
      isMold: yup.bool(),
      preexistingDamage: yup.bool(),
    })
    .required();

  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      mobilizationEta: undefined,
      isAviva: insurer === 'Aviva Canada',
      contentsHandling: insurer === 'Aviva Canada' ? 'notRequired' : undefined,
    },
  });

  useEffect(()=> {
    if (!isMandatoryFieldsInvalid && !isValid) {
      mandatoryFieldsInvalid(true);
    }
    if (isMandatoryFieldsInvalid && isValid) {
      mandatoryFieldsInvalid(false);
    }
  }, [errors, isMandatoryFieldsInvalid, isValid, mandatoryFieldsInvalid]);

  const setValuesForForm = useCallback(
    (_inspectionSiteInfo: any) => {
      if (_inspectionSiteInfo && !initialValuesSet) {
        const values: FormData = {
          claimId: _inspectionSiteInfo.claimId ?? '',
          id: _inspectionSiteInfo.id ?? 0,
          causeOfLoss: _inspectionSiteInfo.causeOfLoss ?? '',
          class: _inspectionSiteInfo.class ?? '',
          categoryOfLoss: _inspectionSiteInfo.categoryOfLoss ?? '',
          categoryOfSource: _inspectionSiteInfo.categoryOfSource ?? '',
          hvacaffected: _inspectionSiteInfo.hvacaffected ?? false,
          suddenLoss: _inspectionSiteInfo.suddenLoss ?? false,
          nonRestorableContent: _inspectionSiteInfo.nonRestorableContent ?? false,
          emAfterHours: _inspectionSiteInfo.emAfterHours ?? false,
          emEstimateNeeded: _inspectionSiteInfo.emEstimateNeeded ?? false,
          repairEstimateNeeded: _inspectionSiteInfo.repairEstimateNeeded ?? false,
          tearOutNeeded: _inspectionSiteInfo.tearOutNeeded ?? false,
          lossOfIncome: _inspectionSiteInfo.lossOfIncome ?? false,
          mobilizationEta: _inspectionSiteInfo.mobilizationEta ?? '',
          emReserve: _inspectionSiteInfo.emReserve ?? 0,
          reReserve: _inspectionSiteInfo.reReserve ?? 0,
          ctReserve: _inspectionSiteInfo.ctReserve ?? 0,
          generalCauseDetails: DOMPurify.sanitize(_inspectionSiteInfo.generalCauseDetails ?? ''),
          preExistingFactors: DOMPurify.sanitize(_inspectionSiteInfo.preExistingFactors ?? ''),
          fieldCrewNotes: DOMPurify.sanitize(_inspectionSiteInfo.fieldCrewNotes ?? ''),
          areaDamages: DOMPurify.sanitize(_inspectionSiteInfo.areaDamages ?? ''),
          contents: DOMPurify.sanitize(_inspectionSiteInfo.contents ?? ''),
          docusketchLink: DOMPurify.sanitize(_inspectionSiteInfo.docusketchLink ?? ''),
          dryingStrategy: _inspectionSiteInfo.dryingStrategy ?? '',
          emEstimatedDuration: _inspectionSiteInfo.emEstimatedDuration ?? '',
          reEstimatedDuration: _inspectionSiteInfo.reEstimatedDuration ?? '',
          contentsHandling: _inspectionSiteInfo.contentsHandling ?? '',
          otherInfoBox: _inspectionSiteInfo.otherInfoBox ?? '',
          isAsbestos: _inspectionSiteInfo.isAsbestos ?? false,
          isMold: _inspectionSiteInfo.isMold ?? false,
          preexistingDamage: _inspectionSiteInfo.preexistingDamage ?? false,
          isAviva: _inspectionSiteInfo.isAviva ?? false,
        };
        Object.keys(values).forEach(key => {
          setValue(key as keyof FormData, values[key as keyof FormData]);
        });
        setInitialValuesSet(true);
      }
    },
    [setValue, initialValuesSet],
  );

  const { isLoading: isLoadingData } = useSiteInspectionInfo(
    userState,
    Number(claimIndx),
    siteInspectionInfo,
    setSiteInspectionInfo,
    undefined,
    setValuesForForm,
  );

  const { mobilizationEta, causeOfLoss: selectedLossType } = watch();
  const params = { lossType: selectedLossType };
  const { data, isLoading, isFetching } = useTemplates(userState, selectedLossType, params);

  useEffect(() => {
    if (!selectedLossType) {
      setIsLossTypeValid(false);
    } else {
      setIsLossTypeValid(true);
    }
  }, [selectedLossType, setIsLossTypeValid]);

  const templateOptions =
    !isLoading && !isFetching
      ? data?.templates?.map((template: { templateName: string; causeOfLoss: string }) => ({
        label: template.templateName,
        value: template.templateName,
      }))
      : [];

  const handleAddClick = (selectedOption: string) => {
    const foundObj = data.templates.find((e: { templateName: string }) => e.templateName === selectedOption);
    setValue('generalCauseDetails', foundObj?.causeOfLoss!);
    setValue('preExistingFactors', foundObj?.preExistingFactors!);
    setValue('areaDamages', foundObj?.areaDamages!);
    setValue('contents', foundObj?.contents!);
  };

  const formatInfoForm = (formData: FormData) => {
    const info: any = { ...formData };

    info.emReserve = Number.parseFloat(info.emReserve);
    info.reReserve = Number.parseFloat(info.reReserve);
    info.ctReserve = Number.parseFloat(info.ctReserve);
    info.id = siteInspectionInfo?.id;
    info.claimId = claimIndx;
    if (info.mobilizationEta === '') {
      info.mobilizationEta = null;
    }

    if (!DETAILED_LOSS_TYPE.includes(selectedLossType)) {
      info.class = '';
      info.categoryOfLoss = '';
      info.categoryOfSource = '';
      setValue('class', '');
      setValue('categoryOfLoss', '');
      setValue('categoryOfSource', '');
    }
    return info;
  };

  const { isLoading: isSaving, mutateAsync } = useSaveSiteInfoMutation();

  const testMandatoryField = async (fieldName: MandatoryFields) => {
    if (errors[fieldName]) {
      switch (fieldName) {
        case MandatoryFields.CauseOfLoss:
          setIsCauseOfLossValid(false);
          break;
        case MandatoryFields.Class:
          setIsClassValid(false);
          break;
        case MandatoryFields.LossCategory:
          setIsLossCategoryValid(false);
          break;
        case MandatoryFields.SourceCategory:
          setIsSourceCategoryValid(false);
          break;
        case MandatoryFields.DryingStrategy:
          setIsDryingStrategyValid(false);
          break;
        default:
          break;
      }
    } else {
      switch (fieldName) {
        case MandatoryFields.CauseOfLoss:
          setIsCauseOfLossValid(true);
          break;
        case MandatoryFields.Class:
          setIsClassValid(true);
          break;
        case MandatoryFields.LossCategory:
          setIsLossCategoryValid(true);
          break;
        case MandatoryFields.SourceCategory:
          setIsSourceCategoryValid(true);
          break;
        case MandatoryFields.DryingStrategy:
          setIsDryingStrategyValid(true);
          break;
        default:
          break;
      }
    }
  };

  const onSubmit = handleSubmit(async (formData: any) => {
    const info = formatInfoForm(formData);
    if (!info.id) {
      const claimInfoStored = await getClaimSiteInfo(Number(info.claimId));
      info.id = Number(claimInfoStored?.info?.id ?? 0);
    }

    if (network.connected) {
      mutateAsync(info)
        .then(async (response) => {
          if (response?.saveInspectionSiteInfo?.status === ApiStatus.SUCCESS) {
            info.id = response.saveInspectionSiteInfo.itemId;
            await storeClaimSiteInfo({
              info,
              claimIndx: Number(claimIndx),
              status: ClaimOnlineStatus.AvailableOffline,
            });
            refresh();
            return;
          }
          throw new Error(response.saveClaim?.message || t('saveSiteInfoError'));
        })
        .catch((e) => {
          const msg = getErrorMsg(e);
          const message = msg || t('saveSiteInfoError');
          setSaveError(message);
        });
    } else {
      await storeClaimSiteInfo({ info, claimIndx: Number(claimIndx), status: ClaimOnlineStatus.ToBeSynched });
      refresh();
    }
  });

  const setMobilizationEta = (v: any) => {
    setValue('mobilizationEta', v.target.value);
  };

  const handleUserChange = () => {
    setHasChanged(true);
  };

  const isISOString = (str: string) => new Date(str).toISOString() === str;

  const getMobilizationEtaString = (mobilizationEta: string | undefined) => {
    if (!mobilizationEta) return '';
    if (!isISOString(mobilizationEta)) return mobilizationEta;
    const etaDate = new Date(mobilizationEta);
    etaDate.setHours(etaDate.getUTCHours());
    etaDate.setMinutes(etaDate.getUTCMinutes());
    return etaDate.toISOString();
  };


  return {
    DETAILED_LOSS_TYPE,
    onSubmit,
    control,
    lossTypeOptions,
    selectedLossType,
    classTypeOptions,
    errors,
    register,
    lossCategoryOptions,
    sourceCategoryOptions,
    handleAddClick,
    templateOptions,
    isSaving,
    isLoadingData,
    saveError,
    hasChanged,
    handleUserChange,
    setSaveError,
    mobilizationEta,
    setMobilizationEta,
    getMobilizationEtaString,
    insurer,
    isLossCategoryValid,
    isClassValid,
    isCauseOfLossValid,
    isSourceCategoryValid,
    isDryingStrategyValid,
    testMandatoryField,
    MandatoryFields,
    trigger,
  };
};

export default SiteInspectionInfoViewModel;
export type { Props };