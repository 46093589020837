import React from 'react';
import FosSelectBigListItem from '../atom/FosSelectBigListItem';

export interface VerticalWrapperProps {
  value?: number,
  name: string,
  label: string,
  options: any,
  error?: string,
  onChange: (newValue: number) => void
}

export const VerticalWrapperComponent: React.FC<VerticalWrapperProps> =  ({ onChange, ...otherProps }) => {
  const handleVerticalChange = (value: number) => {
    onChange(value);
  };

  return (
    <FosSelectBigListItem
      required
      id="addClaimVertical"
      onChange={handleVerticalChange}
      {...otherProps}
    />
  );
};