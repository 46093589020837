import React, { useEffect, useState } from 'react';
import { IonContent, IonModal, IonPage } from '@ionic/react';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import ClaimDetailsHeader from '../molecule/ClaimDetailsHeader';
import './ClaimDetails.css';
import { ClaimDetailsMenuTabs } from './helper/ClaimDetailsMenuHelper';
import ClaimDetailsInfo from '../organism/ClaimDetailsInfo';
import TBD from '../molecule/TBD';
import ClaimPhotos from '../organism/ClaimPhotos';
import { ClaimOnlineStatus } from './helper/Const';
import ClaimDocuments from '../organism/ClaimDocuments';
import ClaimNotes from '../organism/ClaimNotes';
import useClaimDetailsViewModel from './ClaimDetailsViewModel';
import ClaimDetailsContext from '../contexts/ClaimDetails';
import FosModalHeader from '../atom/FosModalHeader';
import ClaimNotificationForm from '../organism/ClaimNotificationForm';

const ClaimDetails: React.FC = () => {
  const {
    claim,
    region,
    isLoading,
    isFetching,
    activePhase,
    isOffline,
    handleYearOfConstruction,
    activeClaimDetailOption,
    changeActiveClaimDetailOption,
    refetchPhotos,
    user,
    claimDocs,
    refetchDocuments,
    isLoadingDocuments,
    localJobIdxString,
    localJobIdx,
    claimNotes,
    refetchNotes,
    isLoadingNotes,
    phases,
    notesCategories,
    statusDesc,
    contactsList,
    refetch,
    setActivePhaseCB,
    handleCallButtonClick,
    handleMapButtonClick,
    openModal,
    setOpenModal,
  } = useClaimDetailsViewModel();
  const [isLinkedToXact, setIsLinkedToXact] = useState(false);
  useEffect(() => {
    if (claim && Array.isArray(claim.phases)) {
      claim.phases.forEach((element: { linkedToXA: boolean; phaseIndx: number }) => {
        if (element.linkedToXA && !isLinkedToXact) {
          setIsLinkedToXact(true);
        }
      });
    }
  }, [claim, isLinkedToXact]);
  const onSaveNotification = (success: boolean) => {
    setOpenModal(false);
    if (success) {
      toast.success(t('claimNotificationSaved'), { duration: 4000 });
    } else {
      toast.error(t('saveNotificationError'), { duration: 4000 });
    }
  };

  return (
    <ClaimDetailsContext.Provider value={claim}>
          <IonPage>
      <ClaimDetailsHeader 
        jobIdx={localJobIdxString}
        phases={phases}
        isFetching={isFetching}
        yearOfConstruction={handleYearOfConstruction()}
        claimNumber={claim?.claimNumber}
        claimStatus={claim?.status || ClaimOnlineStatus.Synched}
        lossType={claim?.lossType}
        setActivePhase={setActivePhaseCB}
        refetchClaim={refetch}
        activeClaimDetailOption={activeClaimDetailOption}
        onClaimDetailOptionPress={changeActiveClaimDetailOption}
        insurer={claim && claim.insurer ? claim.insurer : 'na'}
        />
      {activeClaimDetailOption === ClaimDetailsMenuTabs.ClaimInfo &&
        <ClaimDetailsInfo 
          activePhase={activePhase}
          isLoading={isLoading && !isOffline}
          claim={claim}
          region={region}
          isOffline={isOffline === 'true'}
          statusDesc={statusDesc}
          handleMapButtonClick={handleMapButtonClick}
          handleCallButtonClick={handleCallButtonClick}
        />
      }
      {activeClaimDetailOption === ClaimDetailsMenuTabs.Photos && <ClaimPhotos claimIndx={localJobIdx} phases={phases} refetchPhotos={refetchPhotos}/>}
      {activeClaimDetailOption === ClaimDetailsMenuTabs.Moisture && <IonContent fullscreen><TBD number={1} /></IonContent>}
      {activeClaimDetailOption === ClaimDetailsMenuTabs.Notes && 
        <ClaimNotes
          userName={user?.fullName ?? ''}
          contactsList={contactsList}
          claimIndx={localJobIdxString}
          claimNumber={claim.claimNumber}
          claimNotes={claimNotes}
          phases={phases} 
          categories={notesCategories}
          refetchNotes={refetchNotes}
          isLoading={isLoadingNotes && !isOffline}
          handleMapButtonClick={handleMapButtonClick}
          handleCallButtonClick={handleCallButtonClick}
          defaultPhone=  {claim?.contactPhone || ''}
          isLinkedToXact={isLinkedToXact}
        />}
      {activeClaimDetailOption === ClaimDetailsMenuTabs.Documents && 
        <ClaimDocuments
          claimDocs={claimDocs || []}
          phases={phases}
          claimIndx={localJobIdx}
          refetchDocuments={refetchDocuments}
          isLoading={isLoadingDocuments && !isOffline}
        />}
        <IonModal isOpen={openModal} class="custom-modal">
          <FosModalHeader title={t('createNotification').toString()} onCloseClick={() => setOpenModal(false)} />
          <ClaimNotificationForm
            userName={user?.fullName ?? ''}
            claimIndx={localJobIdxString}
            onSubmit={(success: boolean) => onSaveNotification(success)}
            defaultPhone= {claim?.contactPhone || t('phoneNumberPlaceHolder') }
          />
        </IonModal>
    </IonPage>
    </ClaimDetailsContext.Provider>
  );
};

export default ClaimDetails;
