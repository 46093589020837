import { useEffect, useState } from 'react';
import { GET_MOBILE_USER_ACCESS, ClaimTrakAccessLevel } from '../graphql/GetUser';
import { useAuth, client } from './authContext';

const PERMISSIONS = 'permissions';

export const enum PermissionsList {
  AddClaims = 'AddClaims',
  ViewClaims = 'ViewClaims',
  EditClaims = 'EditClaims',
  AddPhotos = 'AddPhotos',
  EditPhotos = 'EditPhotos',
  AddDocuments = 'AddDocuments',
  DeleteDocuments = 'DeleteDocuments',
  ManageEquipment = 'ManageEquipment',
  AdministerEquipment = 'AdministerEquipment',
  EquipmentManager = 'EquipmentManager',
  EquipmentManager_EditEquipment = 'EquipmentManager_EditEquipment',
  AllowSendToXA = 'AllowSendToXA',
  Notifications = 'Notifications',
  CrewNotification = 'CrewNotification',
}

export const enum AccessLevel {
  SECURITY_FLAG_TECHASSIST = 'SECURITY_FLAG_TECHASSIST',
}

interface SavedPermissions {
  userGuid: string | null;
  permissions: string[];
}

const getSavedPermissions = () => {
  const savedPermissionsStr = window.localStorage.getItem(PERMISSIONS);
  if (!savedPermissionsStr) return { userGuid: null, permissions: [] };
  try {
    const savedPermissions = JSON.parse(savedPermissionsStr) as SavedPermissions;
    return savedPermissions;
  } catch (error) {
    return { userGuid: null, permissions: [] };
  }
};

const savePermissions = (data: SavedPermissions) => {
  window.localStorage.setItem(PERMISSIONS, JSON.stringify(data));
};

export const usePermissions = () => {
  const auth = useAuth();
  const [permissions, setPermissions] = useState<string[]>([]);
  const user = auth?.user;
  const userState = auth?.userState;

  useEffect(() => {
    if (permissions?.length > 0) {
      savePermissions({
        userGuid: user?.userGuid || null,
        permissions,
      });
    }
  }, [permissions, user]);

  const addPermissionsForAccessLevel = (permissions: string[], accessLevel: ClaimTrakAccessLevel) => {
    const removePermission = (permissions: string[], permission: string) => {
      const index = permissions.indexOf(permission);
      if (index !== -1) {
        permissions.splice(index, 1);
      }
    };
    const localPermissions = permissions;
    if (accessLevel?.value === AccessLevel.SECURITY_FLAG_TECHASSIST) {
      localPermissions.push(PermissionsList.ViewClaims);
      localPermissions.push(PermissionsList.CrewNotification);
      removePermission(localPermissions, PermissionsList.AddClaims);
      removePermission(localPermissions, PermissionsList.EditClaims);
      removePermission(localPermissions, PermissionsList.AddDocuments);
      removePermission(localPermissions, PermissionsList.DeleteDocuments);
      removePermission(localPermissions, PermissionsList.AllowSendToXA);
    } else {
      localPermissions.push(PermissionsList.AddDocuments);
      localPermissions.push(PermissionsList.DeleteDocuments);
      localPermissions.push(PermissionsList.AllowSendToXA);
    }
    // for now, all user will be able to add and edit photos
    localPermissions.push(PermissionsList.AddPhotos);
    localPermissions.push(PermissionsList.EditPhotos);
  };

  useEffect(() => {
    const getPermissions = async () => {
      // initialize with stored permissions
      const savedPermissions = getSavedPermissions();
      if (savedPermissions?.permissions?.length > 0) {
        setPermissions(savedPermissions.permissions);
      }

      try {
        if (user) {
          const query: any = user.userGuid ? await client.query({
            query: GET_MOBILE_USER_ACCESS,
            variables: {
              regionId: userState?.userAuthPayload?.regionId,
              userGuid: user?.userGuid,
            },
          }) : {};
          const perm = query?.data?.mobileUserAccess?.permissions || [];
          const permissionsList: string[] = [...perm];

          const accessLevel = query.data?.mobileUserAccess?.claimTrakAccessLevel;
          addPermissionsForAccessLevel(permissionsList, accessLevel);
          /* used to test user without the permission
          const index = permissionsList.indexOf(PermissionsList.AddClaims);
          if (index > -1) {
            const newArray = [...permissionsList];
            newArray.splice(index, 1);
            permissionsList = newArray;
          } 
          */
          setPermissions(permissionsList || []);
        }
      } catch (error) {
        window.console.log('getPermissions error: ', error);
      }
    };

    getPermissions();
  }, [user, userState?.userAuthPayload?.regionId]);

  return { permissions };
};

export default usePermissions;
