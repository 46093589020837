/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { TFunction } from 'i18next';
import { print } from 'graphql';
import gql from 'graphql-tag';
import { ExpenseBaseForm, ExpenseBaseFormData, ExpenseBaseFormErrors, getBaseExpErrors } from './ExpBaseExpenseForm';
import { ExpTax } from './ExpTaxes';
import { JobRelExpenseForm, JobRelExpenseFormData, JobRelExpenseFormErrors, createJobRelExp, getJobRelExpErrors } from './ExpJobRelExpenseForm';
import { UserState } from '../hooks/authUtils';
import { JobRelatedExpenseDtoInput, ExpenseDtoInput, ExpenseReceiptDtoInput } from '../hooks/expSaveExpense';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import { ExpenseMethod } from '../pages/ExpenseMethod';

export type JobRelExpenseFormFullData = {
  id: number
  date: string
  creditCardId?: string
  creditCardNumber: string
  location: string
  currency: string
  tip: string
  merchant: string
  receipts: File[]
  receiptsData: ExpenseReceiptDtoInput[]
  branch: number
  branchName: string
  projectId: string
  projectIdParent?: string
  costType: string
  category: number
  department: string
  totalAmount: string
  taxes: ExpTax[]
  comments: string
  hasTaxes: boolean
  isParent: boolean
  statusId: number
  employeeName?: string
};

export type JobRelExpenseFormFullErrors = ExpenseBaseFormErrors & JobRelExpenseFormErrors;

export interface Props {
  expense: JobRelExpenseFormFullData;
  errors: JobRelExpenseFormFullErrors;
  expenseMethod: ExpenseMethod;
  editMode: boolean;
  onChange: (id: keyof JobRelExpenseFormFullData, value: any, data: JobRelExpenseFormFullData) => any
}

export function createJobRelExpFull(homeBranch: number, homeRegion: string): JobRelExpenseFormFullData {
  return {
    ...createJobRelExp({ branch: homeBranch }),
    date: '',
    creditCardNumber: '',
    creditCardId: undefined,
    location: homeRegion ?? '',
    currency: '',
    merchant: '',
    comments: '',
    receipts: [],
    receiptsData: [],
    tip: '',
    isParent: false,
    statusId: 1, // Pending
  };
}

const saveJobRelExpenseMutation = gql`
mutation(
  $userGuid: UUID!,
  $files: [Upload!],
  $expenses: [CanonicalExpenseDtoInput],  
  $releaseAfterSave: Boolean!
) { 
  saveExpenses(
    userGuid: $userGuid,
    expenses:  $expenses,
    releaseAfterSave: $releaseAfterSave,
    receipts:{
      files: $files
    }
  ) { 
    itemId
    status
    message
  }
}
`;

export async function saveJobRelExpFull(
  userGuid: string,
  userState: UserState,
  jobRelExpensesFull: JobRelExpenseFormFullData[],
  releaseAfterSave: Boolean,
  expenseMethodId: number,
) {
  const formData = new FormData();
  const expenses: JobRelatedExpenseDtoInput[] = [];

  jobRelExpensesFull.forEach((jobRelExpFull: any) => {
    const totalAmount = Number.isNaN(+jobRelExpFull.totalAmount) ? 0 : +jobRelExpFull.totalAmount;
    const taxAmount = jobRelExpFull.taxes?.reduce((amount: any, tax: any) => amount + +tax.total, 0) ?? 0;
    const tip = Number.isNaN(+jobRelExpFull.tip) ? 0 : +jobRelExpFull.tip;
    const netAmount = totalAmount - taxAmount - tip;
    const regionId = userState.userAuthPayload?.regionId!;
    const expense: ExpenseDtoInput = {
      id: jobRelExpFull.id < 1000000 ? jobRelExpFull.id : 0,
      branchId: +jobRelExpFull.branch,
      regionId,
      comments: jobRelExpFull.comments,
      createdByGuid: userGuid,
      currencyId: +jobRelExpFull.currency || +jobRelExpFull.currencyId,
      dateExpense: jobRelExpFull.date || jobRelExpFull.dateExpense,
      dateCreated: new Date().toISOString(),
      employeeGuid: userGuid,
      expenseMethodId,
      creditCardId: jobRelExpFull.creditCardId ? +jobRelExpFull.creditCardId : undefined,
      gpcategoryId: +jobRelExpFull.category === 0 ? undefined : +jobRelExpFull.category,
      gpdepartmentId: jobRelExpFull.department ? +jobRelExpFull.department : 0,
      province: jobRelExpFull.location,
      isParent: jobRelExpFull.isParent ?? false,
      parentId: 0,
      statusId: 1,
      totalAmount,
      netAmount,
      tip,
      taxAmount,
      merchant: jobRelExpFull.merchant,
    };

    const expenseObject: JobRelatedExpenseDtoInput = {
      expenseObject: {
        expense,
        expenseTaxes: jobRelExpFull.taxes?.map((t: any) => ({ ...t, total: +t.total })) ?? [],
        expenseReceipts: jobRelExpFull.receiptsData,
      },
      jobCostId: jobRelExpFull.category?.toString(),
      jobCostTypeId: jobRelExpFull.costType?.toString(),
      jobDepartmentId: jobRelExpFull.department?.toString(),
      expenseClaimId: Number(jobRelExpFull.projectIdParent),
    };
    expenses.push(expenseObject);
  });

  const operations = {
    query: print(saveJobRelExpenseMutation),
    variables: {
      userGuid,
      files: null,
      expenses,
      releaseAfterSave,
    },
  };

  formData.append('operations', JSON.stringify(operations));

  const map: any = {};
  if (jobRelExpensesFull[0].receipts?.length > 0 && !('id' in jobRelExpensesFull[0].receipts[0])) {
    jobRelExpensesFull[0].receipts?.forEach((receipt: any, index: any) => {
      map[index] = ['variables.files'];
      formData.set(`${index}`, receipt);
    });
  }
  formData.append('map', JSON.stringify(map));
  const restClient = getRestClient(userState, ClientType.FORM);
  const res = await restClient.post('/payroll/graphql', formData);
  return res;
}

export async function getJobRelExpenseFullErrors(expense: JobRelExpenseFormFullData, t: TFunction): Promise<JobRelExpenseFormFullErrors> {
  if (expense.isParent) {
    return { ...getBaseExpErrors(expense, t) };
  }

  return {
    ...getBaseExpErrors(expense, t),
    ...getJobRelExpErrors(expense, t),
  };
}

export const JobRelExpenseFormFull: React.FC<Props> = ({ expense, errors, expenseMethod, editMode, onChange }) => {
  const handleExpenseBaseFormChange = (id: keyof ExpenseBaseFormData, value: any, baseExpense: ExpenseBaseFormData) => {
    const exp: JobRelExpenseFormFullData = { ...expense, ...baseExpense };
    onChange(id, value, exp);
  };

  const handleChange = (id: keyof JobRelExpenseFormData, value: any, jobRelExpense: JobRelExpenseFormData) => {
    const exp: JobRelExpenseFormFullData = { ...expense, ...jobRelExpense };
    onChange(id, value, exp);
  };

  return (
    <div className="fos-form ion-padding">
      <div className="ion-margin-bottom">
        <ExpenseBaseForm editMode={editMode} expenseMethod={expenseMethod} totalAmountReadOnly={expenseMethod === ExpenseMethod.CREDIT_CARD}  expense={expense} errors={errors} onChange={handleExpenseBaseFormChange} statusId={expense.statusId}/>
      </div>
      <JobRelExpenseForm editMode={editMode} id="form-full" onChange={handleChange} expense={expense} errors={errors} location={expense.location} />
    </div>
  );
};

export default JobRelExpenseFormFull;
